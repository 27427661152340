.app-header {
    position: relative;

    .nav-desktop {
        position: fixed;
        top: 0vh;
        left: 0vw;
        width: 100%;
        z-index: 1009;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4vh 8vw;
        background: #131312;
        font-family: Arial;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;


        @media only screen and (max-width: 1400px) {
            padding: 4vh 6vw;
        }

        @media only screen and (max-width: 1200px) {
            padding: 4vh 4vw;
        }

        @media only screen and (max-width: 767px) {
            display: none;
        }

        .xemployed-logo {
            cursor: pointer;

            p {
                margin: 0;
                font-size: 2rem;
                font-weight: 700;
                color: #ffffff;

                span {
                    font-size: 2rem;
                    font-weight: 700;
                    color: #15E800;
                }
            }
        }

        .xemployed-menu-container {
            display: flex;
            flex-direction: row;
            gap: 5vw;

            .single-menu {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin: 0vh 1vw;

                @media only screen and (max-width: 900px) {
                    margin: 0vh 0.5vw;
                }

                a {
                    width: max-content;
                    font-size: 1.15em;
                    font-weight: 500;
                    color: white;

                    @media only screen and (max-width: 900px) {
                        font-size: 1.1em;
                    }

                    &:hover {
                        color: #15E800;
                        text-decoration: none;
                    }
                }
            }
        }

        .wallet-already-connected {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1vh 3vw;
            border-radius: 30px;
            font-size: 1.15em;
            font-weight: 500;
            background: #15E800;
            color: #131312;
            cursor: pointer;

            &:hover {
                cursor: pointer;
                background: #20a813;
            }

            @media only screen and (max-width: 900px) {
                font-size: 1.1em;
            }
        }

        .wallet-connect-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding: 1vh 3vw;
            border-radius: 30px;
            font-size: 1.15em;
            font-weight: 500;
            background: #15E800;
            color: #131312;
            cursor: pointer;

            &:hover {
                cursor: pointer;
                background: #20a813;
            }

            @media only screen and (max-width: 900px) {
                font-size: 1.1em;
            }

            .lightening-container {
                padding: 0px 6px 2px 6px;
                border-radius: 7px;
                background: #131312;

                .lightening-icon {
                    font-size: 15px;
                    color: #15E800;
                }
            }
        }
    }

    .nav-mobile {
        position: fixed;
        top: 0vh;
        left: 0vw;
        width: 100%;
        z-index: 1010;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4vh 4vw;
        background: #131312;
        font-family: Arial;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media only screen and (min-width: 768px) {
            display: none;
        }

        .xemployed-logo {
            cursor: pointer;

            p {
                margin: 0;
                font-size: 2rem;
                font-weight: 700;
                color: #ffffff;

                span {
                    font-size: 2rem;
                    font-weight: 700;
                    color: #15E800;
                }
            }
        }

        .nav-expand-btn {
            margin: 0vh 2vw;
            font-size: 1.7em;
            color: white;
            cursor: pointer;
        }
    }

    .nav-mobile-menu {
        position: absolute;
        left: 0vw;
        width: 100%;
        transition: all 0.4s;

        .top-menu-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 4vh 3vw 4vh 3vw;
            gap: 3vh;
            background: #1E1E1E;
            filter: drop-shadow(0px 15px 20px rgba(190, 190, 190, 0.302));

            .close-nav-menu {
                display: flex;
                justify-content: end;
                align-items: center;
                width: 100%;
                font-size: 1.5em;
                gap: 10px;
                color: white;
            }

            .single-menu {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                a {
                    width: max-content;
                    font-size: 1.1em;
                    font-weight: 500;
                    color: white;

                    &:hover {
                        color: #15E800;
                        text-decoration: none;
                    }
                }
            }

            .wallet-already-connected {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1vh 6vw;
                border-radius: 30px;
                font-size: 1.15em;
                font-weight: 500;
                background: #15E800;
                color: #131312;
                cursor: pointer;

                &:hover {
                    cursor: pointer;
                    background: #20a813;
                }

                @media only screen and (max-width: 900px) {
                    font-size: 1.1em;
                }
            }

            .wallet-connect-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                padding: 1vh 6vw;
                border-radius: 30px;
                font-size: 1.15em;
                font-weight: 500;
                background: #15E800;
                color: #131312;
                cursor: pointer;

                &:hover {
                    cursor: pointer;
                    background: #20a813;
                }

                @media only screen and (max-width: 900px) {
                    font-size: 1.1em;
                }

                .lightening-container {
                    padding: 0px 6px 2px 6px;
                    border-radius: 7px;
                    background: #131312;

                    .lightening-icon {
                        font-size: 15px;
                        color: #15E800;
                    }
                }
            }
        }

        .bottom-blank-container {
            width: 100%;
            height: 70vh;
            background: black;
            opacity: 80%;
        }
    }

    .nav-mobile-menu.show {
        position: fixed;
        top: 0vh;
        z-index: 1010;
    }

    .nav-mobile-menu.hide {
        position: fixed;
        top: -120vh;
    }
}