.xemployed-hire {
    width: 100%;
    background: #131312;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .top-hire-title {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 18vh 8vw 10vh 8vw;

        .title-effect {
            text-align: center;
            font-size: 2.3rem;
            font-weight: 800;

            @media only screen and (max-width: 1050px) {
                font-size: 2.2rem;
            }

            @media only screen and (max-width: 992px) {
                font-size: 2rem;
            }

            p {
                margin: 0;
                color: white;

                span {
                    color: white;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke: 0.6px;
                }
            }
        }
    }

    .middle-filter-bar {
        width: 100%;
        padding: 0vh 8vw 4vh 8vw;

        .inner-filter-bar {
            width: 100%;
            padding: 30px;
            border-radius: 20px;
            background: #202020;

            @media only screen and (max-width: 1300px) {
                padding: 20px;
            }

            .left-department-filter {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 10px;
                gap: 10px;

                p {
                    margin: 0;
                    font-size: 1.1rem;
                    color: white;
                }

                .filter-dropdown {
                    width: 100%;
                    margin: 0vh 2vw 0vh 0vw;
                    font-weight: 500;

                    .dropdown-toggle::after {
                        display: none !important;
                    }

                    .dropdown-menu.show {
                        width: 100%;
                        background: #ffffff;
                    }

                    .nav-link {
                        padding: 0;
                    }

                    .filter-by-options {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 1vh 2vw;
                        border-radius: 5px;
                        font-size: 1.1em;
                        background: #ffffff;
                        cursor: pointer;

                        .selected-blank-options {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            gap: 1vw;
                            color: #595959;
                        }

                        .selected-options {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            gap: 1vw;
                            color: #15E800;
                        }

                        .filter-down-icon {
                            font-size: 0.9rem;
                        }
                    }

                    .filter-menu-dropdown {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0.5vh 1vw;
                        font-size: 1.1em;
                        color: #595959;
                    }
                }
            }

            .right-reviews-filter {
                display: flex;
                flex-direction: column;
                padding: 10px;
                gap: 10px;

                p {
                    margin: 0;
                    font-size: 1.1rem;
                    color: white;
                }

                .filter-dropdown {
                    width: 100%;
                    margin: 0vh 2vw 0vh 0vw;
                    font-weight: 500;

                    .dropdown-toggle::after {
                        display: none !important;
                    }

                    .dropdown-menu.show {
                        width: 100%;
                        background: #ffffff;
                    }

                    .nav-link {
                        padding: 0;
                    }

                    .filter-by-options {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 1vh 2vw;
                        border-radius: 5px;
                        font-size: 1.1em;
                        background: #ffffff;
                        cursor: pointer;

                        .selected-blank-options {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            gap: 1vw;
                            color: #595959;
                        }

                        .selected-options {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            gap: 1vw;
                            color: #15E800;
                        }

                        .filter-down-icon {
                            font-size: 0.9rem;
                        }
                    }

                    .filter-menu-dropdown {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0.5vh 1vw;
                        font-size: 1.1em;
                        color: #595959;
                    }
                }
            }

            .post-service {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px;
                gap: 10px;

                .crown-icon {
                    margin-right: 5px;
                    font-size: 1.5rem;
                    color: #15E800;
                }

                p {
                    margin: 0;
                    font-size: 1.1rem;
                    color: #15E800;
                }

                .post-btn-container {
                    display: flex;
                    justify-content: center;

                    .plus-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background: #B3B3B3;
                        cursor: pointer;

                        &:hover {
                            background: #15E800;
                        }

                        .plus-icon {
                            font-size: 20px;
                            color: #202020;
                        }
                    }
                }
            }
        }
    }

    .bottom-all-talents {
        width: 100%;
        padding: 0vh 7vw 10vh 7vw;

        .single-talent-container {
            width: 100%;
            padding: 2vh 1vw;
            cursor: pointer;

            .talent-info {
                display: flex;
                width: 100%;
                border-radius: 20px;
                background: #202020;

                .left-avatar {
                    width: 100%;
                    position: relative;

                    img {
                        width: 100%;
                        height: 400px;
                        object-fit: cover;
                        border-radius: 20px;
                    }

                    .hire-me-badge {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        width: 80px;
                        height: 80px;
                        object-fit: contain;
                    }
                }

                .right-main-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    padding: 2vh 2vw;
                    gap: 0.8vh;

                    @media only screen and (max-width: 1199px) {
                        gap: 2vh;
                    }

                    .talent-name {
                        text-align: center;
                        font-size: 1.8rem;
                        font-weight: 800;

                        @media only screen and (max-width: 1600px) {
                            font-size: 1.4rem;
                        }

                        @media only screen and (max-width: 1199px) {
                            font-size: 1.9rem;
                        }

                        @media only screen and (max-width: 800px) {
                            font-size: 1.4rem;
                        }

                        p {
                            margin: 0;
                            color: white;

                            span {
                                color: white;
                                -webkit-text-fill-color: transparent;
                                -webkit-text-stroke: 0.6px;
                            }
                        }
                    }

                    .talent-job-title {
                        text-align: center;
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: #15E800;

                        @media only screen and (max-width: 1600px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 1199px) {
                            font-size: 1.5rem;
                        }

                        @media only screen and (max-width: 800px) {
                            font-size: 1.2rem;
                        }
                    }

                    .talent-review-count {
                        display: flex;
                        justify-content: center;
                        margin-top: 1vh;
                    }

                    .talent-department {
                        display: flex;
                        flex-direction: column;
                        margin-top: 1vh;
                        color: white;

                        p {
                            margin: 0;
                            text-align: center;
                            font-size: 1.1rem;
                            font-weight: normal;

                            @media only screen and (max-width: 1600px) {
                                font-size: 1.0rem;
                            }

                            @media only screen and (max-width: 1199px) {
                                font-size: 1.2rem;
                            }

                            @media only screen and (max-width: 800px) {
                                font-size: 1.0rem;
                            }
                        }

                        span {
                            text-align: center;
                            font-size: 1.4rem;
                            font-weight: 500;

                            @media only screen and (max-width: 1600px) {
                                font-size: 1.2rem;
                            }

                            @media only screen and (max-width: 1199px) {
                                font-size: 1.5rem;
                            }

                            @media only screen and (max-width: 800px) {
                                font-size: 1.2rem;
                            }
                        }
                    }

                    .talent-social {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-top: 1vh;

                        .social-container {
                            display: flex;
                            flex-direction: row;
                            gap: 20px;

                            .twitter-icon {
                                font-size: 1.6rem;
                                color: #15E800;

                                @media only screen and (max-width: 1600px) {
                                    font-size: 1.4rem;
                                }

                                @media only screen and (max-width: 1199px) {
                                    font-size: 1.7rem;
                                }

                                @media only screen and (max-width: 800px) {
                                    font-size: 1.5rem;
                                }

                                &:hover {
                                    color: white;
                                }
                            }

                            .linkedin-icon {
                                font-size: 1.6rem;
                                color: #15E800;

                                @media only screen and (max-width: 1600px) {
                                    font-size: 1.4rem;
                                }

                                @media only screen and (max-width: 1199px) {
                                    font-size: 1.7rem;
                                }

                                @media only screen and (max-width: 800px) {
                                    font-size: 1.5rem;
                                }

                                &:hover {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}