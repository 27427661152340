.xemployed-account {
    display: flex;
    background: #131312;
    padding: 18vh 0vw 0vh 0vw;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .left-menu-bar {
        display: flex;
        flex-direction: column;
        width: 350px;
        height: auto;
        padding: 40px 30px;
        gap: 1vh;
        border-top-right-radius: 20px;
        background: #202020;

        @media only screen and (max-width: 767px) {
            display: none;
        }

        .single-account-menu {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 30px;
            gap: 20px;
            border: 1px solid #202020;
            border-radius: 20px;
            font-size: 1.1rem;
            font-weight: 600;
            color: white;
            cursor: pointer;

            .menu-icon {
                font-size: 1.5rem;
                color: white;
            }

            &:hover {
                border: 1px solid #15E800;
                color: #15E800;

                .menu-icon {
                    font-size: 1.5rem;
                    color: #15E800;
                }
            }
        }

        .single-account-menu.active {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 30px;
            gap: 20px;
            border: 1px solid #202020;
            border-radius: 20px;
            font-size: 1.1rem;
            font-weight: 600;
            color: #15E800;
            cursor: pointer;

            .menu-icon {
                font-size: 1.5rem;
                color: #15E800;
            }

            &:hover {
                border: 1px solid #15E800;
                color: #15E800;

                .menu-icon {
                    font-size: 1.5rem;
                    color: #15E800;
                }
            }
        }
    }

    .right-menu-detail {
        width: calc(100vw - 350px);

        @media only screen and (max-width: 767px) {
            width: 100vw;
        }
    }
}