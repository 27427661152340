.post-jobs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0vh 40px 40px 40px;

    @media only screen and (max-width: 600px) {
        padding: 0vh 20px 40px 20px;
    }

    .job-type.active {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(16, 16, 16, 0.9);
        z-index: 3;

        .type-modal {
            position: fixed;
            left: calc(50vw - 250px);
            top: 30vh;
            display: flex;
            flex-direction: column;
            width: 500px;
            padding: 20px;
            gap: 2vh;
            border-radius: 20px;
            background: #202020;

            @media only screen and (max-width: 500px) {
                left: 0;
                width: 100%;
                padding: 20px 10px;
            }

            .close-btn {
                display: flex;
                justify-content: right;

                .close-icon {
                    font-size: 1.4rem;
                    color: white;
                    cursor: pointer;
                }
            }

            .up-title {
                text-align: center;
                font-size: 2.1rem;
                font-weight: 800;

                @media only screen and (max-width: 1050px) {
                    font-size: 1.9rem;
                }

                @media only screen and (max-width: 992px) {
                    font-size: 1.8rem;
                }

                p {
                    margin: 0;
                    color: white;

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }

            .down-input-form {
                display: flex;
                flex-direction: column;
                padding: 2vh 30px;
                gap: 3vh;

                @media only screen and (max-width: 500px) {
                    padding: 2vh 15px;
                }

                .submit-btn {
                    width: 100%;
                    padding: 10px 0px;
                    border: 1px solid #15E800;
                    border-radius: 20px;
                    text-align: center;
                    background: #131312;
                    color: #15E800;
                    cursor: pointer;

                    &:hover {
                        border-color: #15E800;
                        background: #15E800;
                        color: #202020;
                    }
                }
            }
        }
    }

    .job-type {
        position: fixed;
        top: -100vh;
        left: 0;
    }

    .top-already-posted {
        display: flex;
        flex-direction: column;
        gap: 4vh;

        .posted-label {
            font-size: 1.3rem;
            font-weight: 600;
            color: white;

            @media only screen and (max-width: 1200px) {
                font-size: 1.2rem;
            }

            @media only screen and (max-width: 600px) {
                font-size: 1.15rem;
            }
        }

        .posted-jobs-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4vh;

            img {
                width: 200px;
            }

            p {
                font-size: 1.3rem;
                font-weight: 600;
                color: white;
            }
        }

        .posted-jobs-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 2vh;

            .single-job-container {
                position: relative;
                width: 100%;
                padding: 2vh 0vw;
                cursor: pointer;

                .featured-container {
                    position: absolute;
                    top: -5px;
                    display: flex;
                    justify-content: center;
                    padding: 1vh 30px;
                    gap: 10px;
                    border: 1px solid black;
                    border-radius: 30px;
                    font-weight: 600;
                    background: #15E800;
                    color: white;
    
                    .crown-icon {
                        font-size: 1.4rem;
                    }
                }

                .job-info {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 20px;
                    gap: 2vh;
                    border-radius: 20px;
                    background: #B3B3B3;

                    .job-primary-info {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .left-info {
                            display: flex;
                            flex-direction: column;
                            gap: 1vh;

                            .job-title {
                                font-size: 1.3rem;
                                font-weight: 600;
                                color: #202020;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.15rem;
                                }
                            }

                            .job-department {
                                font-size: 1.1rem;
                                color: #202020;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }
                        }

                        .right-info {
                            display: flex;
                            flex-direction: column;
                            gap: 2vh;

                            .logo-social-container {
                                display: flex;
                                gap: 10px;

                                @media only screen and (max-width: 600px) {
                                    gap: 0px;
                                }

                                .poster-socials {
                                    display: flex;
                                    align-items: center;
                                    gap: 30px;

                                    @media only screen and (max-width: 600px) {
                                        gap: 15px;
                                    }

                                    .social-icon {
                                        font-size: 1.4rem;
                                        color: #202020;

                                        &:hover {
                                            color: #15E800;
                                        }
                                    }
                                }

                                .poster-logo {
                                    .hexagon {
                                        overflow: hidden;
                                        visibility: hidden;
                                        -webkit-transform: rotate(120deg);
                                        -moz-transform: rotate(120deg);
                                        -ms-transform: rotate(120deg);
                                        -o-transform: rotate(120deg);
                                        transform: rotate(120deg);
                                        cursor: pointer;

                                        .hexagon-in-one {
                                            overflow: hidden;
                                            width: 100%;
                                            height: 100%;
                                            -webkit-transform: rotate(-60deg);
                                            -moz-transform: rotate(-60deg);
                                            -ms-transform: rotate(-60deg);
                                            -o-transform: rotate(-60deg);
                                            transform: rotate(-60deg);

                                            .hexagon-in-two {
                                                width: 100%;
                                                height: 100%;
                                                background-repeat: no-repeat;
                                                background-position: 50%;
                                                background-size: cover;
                                                visibility: visible;
                                                -webkit-transform: rotate(-60deg);
                                                -moz-transform: rotate(-60deg);
                                                -ms-transform: rotate(-60deg);
                                                -o-transform: rotate(-60deg);
                                                transform: rotate(-60deg);
                                            }
                                        }
                                    }

                                    .hexagon-div {
                                        width: 80px;
                                        height: 40px;
                                    }
                                }
                            }

                            .created-time {
                                text-align: right;
                                font-size: 1.1rem;
                                color: #202020;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }
                        }
                    }

                    .job-main-info {
                        display: flex;
                        flex-direction: column;
                        gap: 1vh;

                        .job-salary {
                            display: flex;
                            gap: 10px;
                            font-size: 1.1rem;
                            color: #202020;

                            .salary-icon {
                                font-size: 1.8rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }

                        .job-poster {
                            display: flex;
                            gap: 10px;
                            font-size: 1.1rem;
                            color: #202020;

                            .poster-icon {
                                font-size: 1.8rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }

                        .job-description {
                            max-height: 180px;
                            margin-top: 1vh;
                            font-size: 1.1rem;
                            overflow-y: auto;
                            color: #202020;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }

                        .bottom-job-control {
                            display: flex;
                            justify-content: space-between;

                            .left-job-type {
                                display: flex;
                                margin-top: 1vh;
                                gap: 10px;
                                font-size: 1.1rem;
                                color: #202020;

                                .time-icon {
                                    font-size: 1.8rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }

                            .right-job-control {
                                display: flex;
                                gap: 20px;

                                .edit-job-btn {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 10px;
                                    cursor: pointer;

                                    &:hover {
                                        .icon-container {
                                            background: #15E800;
                                        }

                                        .edit-text {
                                            color: #15E800;
                                        }
                                    }

                                    .icon-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        padding: 5px 5px;
                                        border-radius: 5px;
                                        font-size: 1.3rem;
                                        background: #B3B3B3;
                                        color: #1E1E1E;
                                    }

                                    .edit-text {
                                        font-size: 1.1rem;
                                        font-weight: 600;
                                        text-decoration: underline;
                                        color: #1E1E1E;
                                    }
                                }

                                .delete-job-btn {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 10px;
                                    cursor: pointer;

                                    &:hover {
                                        .icon-container {
                                            background: #15E800;
                                        }

                                        .delete-text {
                                            color: #15E800;
                                        }
                                    }

                                    .icon-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        padding: 5px 5px;
                                        border-radius: 5px;
                                        font-size: 1.3rem;
                                        background: #B3B3B3;
                                        color: #1E1E1E;
                                    }

                                    .delete-text {
                                        font-size: 1.1rem;
                                        font-weight: 600;
                                        text-decoration: underline;
                                        color: #1E1E1E;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .new-job-container {
            display: flex;
            justify-content: center;

            .new-job-btn {
                width: 100%;
                padding: 10px 0px;
                border: 1px solid #15E800;
                border-radius: 20px;
                text-align: center;
                color: #15E800;
                cursor: pointer;

                &:hover {
                    border-color: #15E800;
                    background: #15E800;
                    color: #202020;
                }
            }
        }

        .job-detail-container {
            width: 100%;

            .create-new-job {
                .top-avatar-summary {
                    width: 100%;
                    padding-bottom: 4vh;

                    .row {
                        display: flex;
                    }

                    .avatar-upload {
                        position: relative;
                        width: 100%;
                        aspect-ratio: 1/1;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 50px;
                        border: 1px solid white;

                        .upload-image-btn {
                            position: absolute;
                            right: 20px;
                            bottom: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;

                            &:hover {
                                .icon-container {
                                    background: #15E800;
                                }

                                .upload-text {
                                    color: #15E800;
                                }
                            }

                            .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 5px 5px;
                                border-radius: 5px;
                                font-size: 1.3rem;
                                background: #B3B3B3;
                                color: #1E1E1E;
                            }

                            .upload-text {
                                font-size: 1.1rem;
                                font-weight: 600;
                                text-decoration: underline;
                                color: #1E1E1E;
                            }
                        }
                    }

                    .summary-container {
                        display: flex;
                        flex-direction: column;
                        padding-left: 40px;
                        gap: 2vh;

                        @media only screen and (max-width: 1199px) {
                            padding: 2vh 0vw;
                        }

                        .up-summary-title {
                            font-size: 1.3rem;
                            font-weight: 600;
                            color: white;

                            @media only screen and (max-width: 1200px) {
                                font-size: 1.2rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.15rem;
                            }
                        }

                        .down-summary-edit {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            color: white;

                            .form-control {
                                height: 100%;
                                padding: 10px 15px;
                                border-radius: 10px;
                                background: #202020;
                                font-size: 1.05rem;
                                word-spacing: 2px;
                                color: white;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1rem;
                                }

                                @media only screen and (max-width: 1199px) {
                                    height: 300px;
                                }
                            }

                            .edit-summary-btn {
                                position: absolute;
                                right: 20px;
                                bottom: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                cursor: pointer;

                                &:hover {
                                    .icon-container {
                                        background: #15E800;
                                    }

                                    .upload-text {
                                        color: #15E800;
                                    }
                                }

                                .icon-container {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 5px 5px;
                                    border-radius: 5px;
                                    font-size: 1.3rem;
                                    background: #B3B3B3;
                                    color: #1E1E1E;
                                }

                                .upload-text {
                                    font-size: 1.1rem;
                                    font-weight: 600;
                                    text-decoration: underline;
                                    color: #B3B3B3;
                                }
                            }
                        }
                    }

                    .department-type {
                        width: 100%;
                        margin: 1vh 0vw;

                        .choose-department-box {
                            display: flex;
                            flex-direction: column;
                            gap: 2vh;

                            .up-label {
                                font-size: 1.3rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.2rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.15rem;
                                }
                            }

                            .down-select {
                                width: 100%;

                                .filter-dropdown {
                                    width: 100%;
                                    margin: 0vh 2vw 0vh 0vw;

                                    .dropdown-toggle::after {
                                        display: none !important;
                                    }

                                    .dropdown-menu.show {
                                        width: 100%;
                                        background: #202020;
                                    }

                                    .nav-link {
                                        padding: 0;
                                    }

                                    .filter-by-options {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        width: 100%;
                                        padding: 7px 15px;
                                        border: 1px solid white;
                                        border-radius: 10px;
                                        font-size: 1.1em;
                                        background: #202020;
                                        cursor: pointer;

                                        .selected-blank-options {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            flex-direction: row;
                                            gap: 1vw;
                                            color: #595959;
                                        }

                                        .selected-options {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            flex-direction: row;
                                            gap: 1vw;
                                            color: white;
                                        }

                                        .filter-down-icon {
                                            font-size: 0.9rem;
                                            color: white;
                                        }
                                    }

                                    .filter-menu-dropdown {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin: 0.5vh 1vw;
                                        font-size: 1.1em;
                                        color: white;
                                    }
                                }
                            }
                        }

                        .job-type-container {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding-left: 20px;
                            gap: 2vh;

                            @media only screen and (max-width: 991px) {
                                padding-left: 0px;
                                margin-top: 2vh;
                            }

                            .up-label {
                                font-size: 1.3rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.2rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.15rem;
                                }
                            }

                            .select-job-type {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;

                                .single-type-box {
                                    width: 47%;
                                    padding: 7px 0px;
                                    border-radius: 20px;
                                    text-align: center;
                                    font-size: 1rem;
                                    border: 1px solid #202020;
                                    background: #202020;
                                    color: white;
                                    cursor: pointer;

                                    &:hover {
                                        border-color: #15E800;
                                    }
                                }

                                .single-type-box.active {
                                    width: 47%;
                                    padding: 7px 0px;
                                    border-radius: 20px;
                                    text-align: center;
                                    font-size: 1rem;
                                    border: 1px solid #15E800;
                                    background: #15E800;
                                    color: #202020;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .single-edit-box {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin: 1vh 0vw;
                        gap: 2vh;

                        .up-label {
                            font-size: 1.3rem;
                            font-weight: 600;
                            color: white;

                            @media only screen and (max-width: 1200px) {
                                font-size: 1.2rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.15rem;
                            }
                        }

                        .down-input {
                            width: 100%;

                            .form-control {
                                padding: 7px 15px;
                                border-radius: 10px;
                                background: #202020;
                                font-size: 1.05rem;
                                word-spacing: 2px;
                                color: white;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }

                .discard-create-container {
                    width: 100%;

                    .create-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-right: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-right: 0px;
                        }

                        .create-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #15E800;
                            border-radius: 20px;
                            text-align: center;
                            background: #131312;
                            color: #15E800;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                background: #15E800;
                                color: #202020;
                            }
                        }
                    }

                    .discard-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-left: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-left: 0px;
                        }

                        .discard-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #B3B3B3;
                            border-radius: 20px;
                            text-align: center;
                            color: white;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                color: #15E800;
                            }
                        }
                    }
                }
            }

            .edit-existing-job {
                .top-avatar-summary {
                    width: 100%;
                    padding-bottom: 4vh;

                    .row {
                        display: flex;
                    }

                    .avatar-upload {
                        position: relative;
                        width: 100%;
                        aspect-ratio: 1/1;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 50px;
                        border: 1px solid white;

                        .upload-image-btn {
                            position: absolute;
                            right: 20px;
                            bottom: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;

                            &:hover {
                                .icon-container {
                                    background: #15E800;
                                }

                                .upload-text {
                                    color: #15E800;
                                }
                            }

                            .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 5px 5px;
                                border-radius: 5px;
                                font-size: 1.3rem;
                                background: #B3B3B3;
                                color: #1E1E1E;
                            }

                            .upload-text {
                                font-size: 1.1rem;
                                font-weight: 600;
                                text-decoration: underline;
                                color: #1E1E1E;
                            }
                        }
                    }

                    .summary-container {
                        display: flex;
                        flex-direction: column;
                        padding-left: 40px;
                        gap: 2vh;

                        @media only screen and (max-width: 1199px) {
                            padding: 2vh 0vw;
                        }

                        .up-summary-title {
                            font-size: 1.3rem;
                            font-weight: 600;
                            color: white;

                            @media only screen and (max-width: 1200px) {
                                font-size: 1.2rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.15rem;
                            }
                        }

                        .down-summary-edit {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            color: white;

                            .form-control {
                                height: 100%;
                                padding: 10px 15px;
                                border-radius: 10px;
                                background: #202020;
                                font-size: 1.05rem;
                                word-spacing: 2px;
                                color: white;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1rem;
                                }

                                @media only screen and (max-width: 1199px) {
                                    height: 300px;
                                }
                            }

                            .edit-summary-btn {
                                position: absolute;
                                right: 20px;
                                bottom: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                cursor: pointer;

                                &:hover {
                                    .icon-container {
                                        background: #15E800;
                                    }

                                    .upload-text {
                                        color: #15E800;
                                    }
                                }

                                .icon-container {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 5px 5px;
                                    border-radius: 5px;
                                    font-size: 1.3rem;
                                    background: #B3B3B3;
                                    color: #1E1E1E;
                                }

                                .upload-text {
                                    font-size: 1.1rem;
                                    font-weight: 600;
                                    text-decoration: underline;
                                    color: #B3B3B3;
                                }
                            }
                        }
                    }

                    .department-type {
                        width: 100%;
                        margin: 1vh 0vw;

                        .choose-department-box {
                            display: flex;
                            flex-direction: column;
                            gap: 2vh;

                            .up-label {
                                font-size: 1.3rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.2rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.15rem;
                                }
                            }

                            .down-select {
                                width: 100%;

                                .filter-dropdown {
                                    width: 100%;
                                    margin: 0vh 2vw 0vh 0vw;

                                    .dropdown-toggle::after {
                                        display: none !important;
                                    }

                                    .dropdown-menu.show {
                                        width: 100%;
                                        background: #202020;
                                    }

                                    .nav-link {
                                        padding: 0;
                                    }

                                    .filter-by-options {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        width: 100%;
                                        padding: 7px 15px;
                                        border: 1px solid white;
                                        border-radius: 10px;
                                        font-size: 1.1em;
                                        background: #202020;
                                        cursor: pointer;

                                        .selected-blank-options {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            flex-direction: row;
                                            gap: 1vw;
                                            color: #595959;
                                        }

                                        .selected-options {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            flex-direction: row;
                                            gap: 1vw;
                                            color: white;
                                        }

                                        .filter-down-icon {
                                            font-size: 0.9rem;
                                            color: white;
                                        }
                                    }

                                    .filter-menu-dropdown {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin: 0.5vh 1vw;
                                        font-size: 1.1em;
                                        color: white;
                                    }
                                }
                            }
                        }

                        .job-type-container {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding-left: 20px;
                            gap: 2vh;

                            @media only screen and (max-width: 991px) {
                                padding-left: 0px;
                                margin-top: 2vh;
                            }

                            .up-label {
                                font-size: 1.3rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.2rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.15rem;
                                }
                            }

                            .select-job-type {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;

                                .single-type-box {
                                    width: 47%;
                                    padding: 7px 0px;
                                    border-radius: 20px;
                                    text-align: center;
                                    font-size: 1rem;
                                    border: 1px solid #202020;
                                    background: #202020;
                                    color: white;
                                    cursor: pointer;

                                    &:hover {
                                        border-color: #15E800;
                                    }
                                }

                                .single-type-box.active {
                                    width: 47%;
                                    padding: 7px 0px;
                                    border-radius: 20px;
                                    text-align: center;
                                    font-size: 1rem;
                                    border: 1px solid #15E800;
                                    background: #15E800;
                                    color: #202020;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .single-edit-box {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin: 1vh 0vw;
                        gap: 2vh;

                        .up-label {
                            font-size: 1.3rem;
                            font-weight: 600;
                            color: white;

                            @media only screen and (max-width: 1200px) {
                                font-size: 1.2rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.15rem;
                            }
                        }

                        .down-input {
                            width: 100%;

                            .form-control {
                                padding: 7px 15px;
                                border-radius: 10px;
                                background: #202020;
                                font-size: 1.05rem;
                                word-spacing: 2px;
                                color: white;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }

                .discard-save-container {
                    width: 100%;

                    .save-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-right: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-right: 0px;
                        }

                        .save-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #B3B3B3;
                            border-radius: 20px;
                            text-align: center;
                            background: #B3B3B3;
                            color: white;
                            cursor: pointer;
                        }

                        .save-btn.active {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #15E800;
                            border-radius: 20px;
                            text-align: center;
                            background: #131312;
                            color: #15E800;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                background: #15E800;
                                color: #202020;
                            }
                        }
                    }

                    .discard-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-left: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-left: 0px;
                        }

                        .discard-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #B3B3B3;
                            border-radius: 20px;
                            text-align: center;
                            color: white;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                color: #15E800;
                            }
                        }
                    }
                }
            }
        }
    }
}