.xemployed-employ {
    width: 100%;
    background: #131312;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .top-employ-title {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 18vh 8vw 10vh 8vw;

        .title-effect {
            text-align: center;
            font-size: 2.3rem;
            font-weight: 800;

            @media only screen and (max-width: 1050px) {
                font-size: 2.2rem;
            }

            @media only screen and (max-width: 992px) {
                font-size: 2rem;
            }

            p {
                margin: 0;
                color: white;

                span {
                    color: white;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke: 0.6px;
                }
            }
        }
    }

    .middle-filter-bar {
        width: 100%;
        padding: 0vh 8vw 4vh 8vw;

        .inner-filter-bar {
            width: 100%;
            padding: 30px;
            border-radius: 20px;
            background: #202020;

            @media only screen and (max-width: 1300px) {
                padding: 20px;
            }

            .left-department-filter {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 10px;
                gap: 10px;

                p {
                    margin: 0;
                    font-size: 1.1rem;
                    color: white;
                }

                .filter-dropdown {
                    width: 100%;
                    margin: 0vh 2vw 0vh 0vw;
                    font-weight: 500;

                    .dropdown-toggle::after {
                        display: none !important;
                    }

                    .dropdown-menu.show {
                        width: 100%;
                        background: #ffffff;
                    }

                    .nav-link {
                        padding: 0;
                    }

                    .filter-by-options {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 1vh 2vw;
                        border-radius: 5px;
                        font-size: 1.1em;
                        background: #ffffff;
                        cursor: pointer;

                        .selected-blank-options {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            gap: 1vw;
                            color: #595959;
                        }

                        .selected-options {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            gap: 1vw;
                            color: #15E800;
                        }

                        .filter-down-icon {
                            font-size: 0.9rem;
                        }
                    }

                    .filter-menu-dropdown {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0.5vh 1vw;
                        font-size: 1.1em;
                        color: #595959;
                    }
                }
            }

            .right-date-filter {
                display: flex;
                flex-direction: column;
                padding: 10px;
                gap: 10px;

                p {
                    margin: 0;
                    font-size: 1.1rem;
                    color: white;
                }

                .filter-dropdown {
                    width: 100%;
                    margin: 0vh 2vw 0vh 0vw;
                    font-weight: 500;

                    .dropdown-toggle::after {
                        display: none !important;
                    }

                    .dropdown-menu.show {
                        width: 100%;
                        background: #ffffff;
                    }

                    .nav-link {
                        padding: 0;
                    }

                    .filter-by-options {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 1vh 2vw;
                        border-radius: 5px;
                        font-size: 1.1em;
                        background: #ffffff;
                        cursor: pointer;

                        .selected-blank-options {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            gap: 1vw;
                            color: #595959;
                        }

                        .selected-options {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            gap: 1vw;
                            color: #15E800;
                        }

                        .filter-down-icon {
                            font-size: 0.9rem;
                        }
                    }

                    .filter-menu-dropdown {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0.5vh 1vw;
                        font-size: 1.1em;
                        color: #595959;
                    }
                }
            }

            .post-job {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px;
                gap: 10px;

                .crown-icon {
                    margin-right: 5px;
                    font-size: 1.5rem;
                    color: #15E800;
                }

                p {
                    margin: 0;
                    font-size: 1.1rem;
                    color: #15E800;
                }

                .post-btn-container {
                    display: flex;
                    justify-content: center;

                    .plus-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background: #B3B3B3;
                        cursor: pointer;

                        &:hover {
                            background: #15E800;
                        }

                        .plus-icon {
                            font-size: 20px;
                            color: #202020;
                        }
                    }
                }
            }
        }
    }

    .bottom-all-jobs {
        width: 100%;
        padding: 0vh 7vw 10vh 7vw;

        .single-job-container {
            width: 100%;
            padding: 2vh 1vw;
            position: relative;
            cursor: pointer;

            .featured-container {
                position: absolute;
                top: -5px;
                display: flex;
                justify-content: center;
                padding: 1vh 30px;
                gap: 10px;
                border-radius: 30px;
                font-weight: 600;
                background: #15E800;
                color: white;

                .crown-icon {
                    font-size: 1.4rem;
                }
            }

            .job-info {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 20px;
                gap: 2vh;
                border: 1px solid #15E800;
                border-radius: 20px;
                background: #202020;

                .job-primary-info {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .left-info {
                        display: flex;
                        flex-direction: column;
                        gap: 1vh;

                        .poster-address {
                            font-size: 1.2rem;
                            font-weight: 500;
                            color: #15E800;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.1rem;
                            }
                        }

                        .job-title {
                            font-size: 1.3rem;
                            font-weight: 600;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.15rem;
                            }
                        }

                        .job-department {
                            font-size: 1.1rem;
                            color: #15E800;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }
                    }

                    .right-info {
                        display: flex;
                        flex-direction: column;
                        gap: 2vh;

                        .logo-social-container {
                            display: flex;
                            gap: 10px;

                            @media only screen and (max-width: 600px) {
                                gap: 0px;
                            }

                            .poster-socials {
                                display: flex;
                                align-items: center;
                                gap: 30px;

                                @media only screen and (max-width: 600px) {
                                    gap: 15px;
                                }

                                @media only screen and (max-width: 500px) {
                                    gap: 10px;
                                }

                                .social-icon {
                                    font-size: 1.4rem;
                                    color: #15E800;

                                    &:hover {
                                        color: white;
                                    }
                                }
                            }

                            .poster-logo {
                                .hexagon {
                                    overflow: hidden;
                                    visibility: hidden;
                                    -webkit-transform: rotate(120deg);
                                    -moz-transform: rotate(120deg);
                                    -ms-transform: rotate(120deg);
                                    -o-transform: rotate(120deg);
                                    transform: rotate(120deg);
                                    cursor: pointer;

                                    .hexagon-in-one {
                                        overflow: hidden;
                                        width: 100%;
                                        height: 100%;
                                        -webkit-transform: rotate(-60deg);
                                        -moz-transform: rotate(-60deg);
                                        -ms-transform: rotate(-60deg);
                                        -o-transform: rotate(-60deg);
                                        transform: rotate(-60deg);

                                        .hexagon-in-two {
                                            width: 100%;
                                            height: 100%;
                                            background-repeat: no-repeat;
                                            background-position: 50%;
                                            background-size: cover;
                                            visibility: visible;
                                            -webkit-transform: rotate(-60deg);
                                            -moz-transform: rotate(-60deg);
                                            -ms-transform: rotate(-60deg);
                                            -o-transform: rotate(-60deg);
                                            transform: rotate(-60deg);
                                        }
                                    }
                                }

                                .hexagon-div {
                                    width: 80px;
                                    height: 40px;

                                    @media only screen and (max-width: 500px) {
                                        width: 70px;
                                        height: 35px;
                                    }
                                }
                            }
                        }

                        .created-time {
                            text-align: right;
                            font-size: 1.1rem;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }
                    }
                }

                .job-main-info {
                    display: flex;
                    flex-direction: column;
                    gap: 1vh;

                    .job-salary {
                        display: flex;
                        gap: 10px;
                        font-size: 1.1rem;
                        color: white;

                        .salary-icon {
                            font-size: 1.8rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.05rem;
                        }
                    }

                    .job-poster {
                        display: flex;
                        gap: 10px;
                        font-size: 1.1rem;
                        color: white;

                        .poster-icon {
                            font-size: 1.8rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.05rem;
                        }
                    }

                    .job-description {
                        max-height: 180px;
                        margin-top: 1vh;
                        font-size: 1.1rem;
                        overflow-y: auto;
                        color: white;

                        @media only screen and (max-width: 600px) {
                            font-size: 1.05rem;
                        }
                    }

                    .bottom-control {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .job-type {
                            display: flex;
                            margin-top: 1vh;
                            gap: 10px;
                            font-size: 1.1rem;
                            color: #15E800;

                            .time-icon {
                                font-size: 1.8rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }

                        .view-detail {
                            font-size: 1.1rem;
                            font-weight: normal;
                            color: #15E800;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                    }
                }
            }
        }
    }
}