.article-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18vh 0vw 10vh 0vw;
    background: #131312;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .article-view-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4vh;

        .article-back {
            display: flex;
            justify-content: space-between;
            margin: 0vh 8vw;
            padding: 20px;
            border-radius: 20px;
            background: #202020;

            .left-go-back {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 10px;
                background: #B3B3B3;
                font-size: 1.5rem;
                color: #202020;
                cursor: pointer;

                &:hover {
                    background: #15E800;
                }
            }
        }

        .article-info-container {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 380px;
            padding: 40px 30% 70px 30%;
            gap: 4vh;
            background: #202020;

            @media only screen and (max-width: 1199px) {
                padding: 40px 20% 70px 20%;
            }

            @media only screen and (max-width: 600px) {
                padding: 40px 10% 70px 10%;
            }

            .top-article-title {
                text-align: center;
                font-size: 1.9rem;
                font-weight: 900;
                color: white;

                @media only screen and (max-width: 1199px) {
                    font-size: 1.7rem;
                }
            }

            .bottom-writer {
                text-align: center;
                font-size: 1.2rem;
                color: white;

                @media only screen and (max-width: 1199px) {
                    font-size: 1.1rem;
                }
            }
            
            .social-container {
                display: flex;
                justify-content: center;

                .twitter-social-icons {
                    font-size: 1.5rem;
                    color: white;
    
                    &:hover {
                        color: #15E800;
                    }
                }    
            }

            .cover-image {
                position: absolute;
                left: 0vw;
                bottom: -360px;
                width: 100%;
                height: 400px;
                padding: 0vh 8vw;
                z-index: 10;

                img {
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
        }

        .article-detail {
            width: 100%;
            padding: 0vh 8vw;
            font-size: 1.1rem;
            color: white;

            img {
                width: 100%;
                object-fit: contain;
            }

            @media only screen and (max-width: 1199px) {
                font-size: 1.05rem;
            }
        }
    }
}