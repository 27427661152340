.profile-edit {
    width: 100%;
    padding: 0vh 40px 40px 40px;

    @media only screen and (max-width: 600px) {
        padding: 0vh 20px 40px 20px;
    }

    .edit-profile-box {
        .top-avatar-summary {
            width: 100%;
            padding-bottom: 4vh;

            .row {
                display: flex;
            }

            .avatar-upload {
                position: relative;
                width: 100%;
                aspect-ratio: 1/1;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 50px;
                border: 1px solid white;

                .upload-image-btn {
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;

                    &:hover {
                        .icon-container {
                            background: #15E800;
                        }

                        .upload-text {
                            color: #15E800;
                        }
                    }

                    .icon-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 5px;
                        border-radius: 5px;
                        font-size: 1.3rem;
                        background: #B3B3B3;
                        color: #1E1E1E;
                    }

                    .upload-text {
                        font-size: 1.1rem;
                        font-weight: 600;
                        text-decoration: underline;
                        color: #1E1E1E;
                    }
                }
            }

            .summary-container {
                display: flex;
                flex-direction: column;
                padding-left: 40px;
                gap: 2vh;

                @media only screen and (max-width: 1199px) {
                    padding: 2vh 0vw;
                }

                .up-summary-title {
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: white;

                    @media only screen and (max-width: 1200px) {
                        font-size: 1.2rem;
                    }

                    @media only screen and (max-width: 600px) {
                        font-size: 1.15rem;
                    }
                }

                .down-summary-edit {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    color: white;

                    .form-control {
                        height: 100%;
                        padding: 10px 15px;
                        border-radius: 10px;
                        background: #202020;
                        font-size: 1.05rem;
                        word-spacing: 2px;
                        color: white;

                        @media only screen and (max-width: 600px) {
                            font-size: 1rem;
                        }

                        @media only screen and (max-width: 1199px) {
                            height: 300px;
                        }
                    }

                    .edit-summary-btn {
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;

                        &:hover {
                            .icon-container {
                                background: #15E800;
                            }

                            .upload-text {
                                color: #15E800;
                            }
                        }

                        .icon-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 5px 5px;
                            border-radius: 5px;
                            font-size: 1.3rem;
                            background: #B3B3B3;
                            color: #1E1E1E;
                        }

                        .upload-text {
                            font-size: 1.1rem;
                            font-weight: 600;
                            text-decoration: underline;
                            color: #B3B3B3;
                        }
                    }
                }
            }
        }

        .middle-primary-info {
            width: 100%;

            .left-hire-me {
                display: flex;
                width: 100%;
                gap: 20px;

                .open-to-work {
                    width: 100px;
                    cursor: pointer;

                    img {
                        width: 100%;
                    }
                }

                .hire-me-text {
                    display: flex;
                    font-size: 1.05rem;
                    font-weight: 500;
                    color: white;

                    @media only screen and (max-width: 767px) {
                        font-size: 1rem;
                    }
                }
            }

            .right-primary {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-left: 40px;
                gap: 2vh;

                @media only screen and (max-width: 1199px) {
                    padding: 4vh 0vw;
                }

                .single-edit-box {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .up-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.15rem;
                        }
                    }

                    .down-input {
                        width: 100%;

                        .form-control {
                            padding: 7px 15px;
                            border-radius: 10px;
                            background: #202020;
                            font-size: 1.05rem;
                            word-spacing: 2px;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1rem;
                            }
                        }
                    }
                }

                .choose-department-box {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .up-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.15rem;
                        }
                    }

                    .down-select {
                        width: 100%;

                        .filter-dropdown {
                            width: 100%;
                            margin: 0vh 2vw 0vh 0vw;

                            .dropdown-toggle::after {
                                display: none !important;
                            }

                            .dropdown-menu.show {
                                width: 100%;
                                background: #202020;
                            }

                            .nav-link {
                                padding: 0;
                            }

                            .filter-by-options {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                padding: 7px 15px;
                                border: 1px solid white;
                                border-radius: 10px;
                                font-size: 1.1em;
                                background: #202020;
                                cursor: pointer;

                                .selected-blank-options {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: row;
                                    gap: 1vw;
                                    color: #595959;
                                }

                                .selected-options {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: row;
                                    gap: 1vw;
                                    color: white;
                                }

                                .filter-down-icon {
                                    font-size: 0.9rem;
                                    color: white;
                                }
                            }

                            .filter-menu-dropdown {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 0.5vh 1vw;
                                font-size: 1.1em;
                                color: white;
                            }
                        }
                    }
                }

                .add-skill-box {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .up-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.15rem;
                        }
                    }

                    .down-skills {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 2vh;

                        .form-control {
                            padding: 7px 15px;
                            border-radius: 10px;
                            background: #202020;
                            font-size: 1.05rem;
                            word-spacing: 2px;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1rem;
                            }
                        }

                        .skills-container {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            gap: 10px;

                            .single-skill {
                                display: flex;
                                align-items: center;
                                padding: 5px 20px;
                                gap: 10px;
                                border-radius: 20px;
                                background: #B3B3B3;
                                font-size: 1rem;
                                color: #202020;
                                cursor: pointer;

                                .close-icon {
                                    font-size: 1.3rem;
                                }

                                &:hover {
                                    background: #15E800;
                                    color: #202020;
                                }
                            }
                        }
                    }
                }

                .add-experience-box {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .up-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.15rem;
                        }
                    }

                    .down-experience-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 4vh;

                        .single-experience-box {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            gap: 1vh;

                            .up-title {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;

                                .left-job-title {
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                    color: white;

                                    @media only screen and (max-width: 1350px) {
                                        font-size: 1.1rem;
                                    }
                                }

                                .right-job-type {
                                    padding: 10px 20px;
                                    border-radius: 20px;
                                    font-size: 1rem;
                                    background: #202020;
                                    color: white;
                                }
                            }

                            .down-experience {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;

                                .left-company-title {
                                    font-size: 1.15rem;
                                    color: white;

                                    @media only screen and (max-width: 1350px) {
                                        font-size: 1.1rem;
                                    }
                                }

                                .right-experience-period {
                                    font-size: 1rem;
                                    color: white;
                                }
                            }

                            .bottom-divider {
                                margin-top: 1vh;
                                background: #B3B3B3;
                            }

                            .remove-container {
                                display: flex;
                                justify-content: right;

                                .remove-icon {
                                    font-size: 1.7rem;
                                    color: white;
                                    cursor: pointer;

                                    &:hover {
                                        color: #15E800;
                                    }
                                }
                            }
                        }
                    }

                    .new-experience-box {
                        width: 100%;

                        .single-left-box {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 2vh;
                            padding-right: 20px;
                            gap: 2vh;

                            @media only screen and (max-width: 991px) {
                                padding-right: 0px;
                            }

                            .up-label {
                                font-size: 1.15rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.1rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }

                            .down-input {
                                width: 100%;

                                .form-control {
                                    padding: 7px 15px;
                                    border-radius: 10px;
                                    background: #202020;
                                    font-size: 1.05rem;
                                    word-spacing: 2px;
                                    color: white;

                                    @media only screen and (max-width: 600px) {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }

                        .single-right-box {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 2vh;
                            padding-left: 20px;
                            gap: 2vh;

                            @media only screen and (max-width: 991px) {
                                padding-left: 0px;
                            }

                            .up-label {
                                font-size: 1.15rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.1rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }

                            .down-input {
                                width: 100%;

                                .form-control {
                                    padding: 7px 15px;
                                    border-radius: 10px;
                                    background: #202020;
                                    font-size: 1.05rem;
                                    word-spacing: 2px;
                                    color: white;

                                    @media only screen and (max-width: 600px) {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }

                        .select-job-type {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin: 2vh 0vw;
                            padding-right: 20px;

                            @media only screen and (max-width: 991px) {
                                padding-right: 0px;
                            }

                            .single-type-box {
                                width: 47%;
                                padding: 7px 0px;
                                border-radius: 20px;
                                text-align: center;
                                font-size: 1rem;
                                border: 1px solid #202020;
                                background: #202020;
                                color: white;
                                cursor: pointer;

                                &:hover {
                                    border-color: #15E800;
                                }
                            }

                            .single-type-box.active {
                                width: 47%;
                                padding: 7px 0px;
                                border-radius: 20px;
                                text-align: center;
                                font-size: 1rem;
                                border: 1px solid #15E800;
                                background: #15E800;
                                color: #202020;
                                cursor: pointer;
                            }
                        }

                        .submit-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            padding-left: 20px;

                            @media only screen and (max-width: 991px) {
                                padding-left: 0px;
                                margin-top: 2vh;
                            }

                            .submit-btn {
                                width: 100%;
                                padding: 10px 0px;
                                border: 1px solid #15E800;
                                border-radius: 20px;
                                text-align: center;
                                color: #15E800;
                                cursor: pointer;

                                &:hover {
                                    border-color: #15E800;
                                    background: #15E800;
                                    color: #202020;
                                }
                            }
                        }
                    }
                }

                .discard-save-container {
                    width: 100%;

                    .save-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-right: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-right: 0px;
                        }

                        .save-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #B3B3B3;
                            border-radius: 20px;
                            text-align: center;
                            background: #B3B3B3;
                            color: white;
                            cursor: pointer;
                        }

                        .save-btn.active {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #15E800;
                            border-radius: 20px;
                            text-align: center;
                            background: #131312;
                            color: #15E800;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                background: #15E800;
                                color: #202020;
                            }
                        }
                    }

                    .discard-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-left: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-left: 0px;
                        }

                        .discard-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #B3B3B3;
                            border-radius: 20px;
                            text-align: center;
                            color: white;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                color: #15E800;
                            }
                        }
                    }
                }
            }
        }
    }

    .create-profile-box {
        .top-avatar-summary {
            width: 100%;
            padding-bottom: 4vh;

            .row {
                display: flex;
            }

            .avatar-upload {
                position: relative;
                width: 100%;
                aspect-ratio: 1/1;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 50px;
                border: 1px solid white;

                .upload-image-btn {
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;

                    &:hover {
                        .icon-container {
                            background: #15E800;
                        }

                        .upload-text {
                            color: #15E800;
                        }
                    }

                    .icon-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 5px;
                        border-radius: 5px;
                        font-size: 1.3rem;
                        background: #B3B3B3;
                        color: #1E1E1E;
                    }

                    .upload-text {
                        font-size: 1.1rem;
                        font-weight: 600;
                        text-decoration: underline;
                        color: #1E1E1E;
                    }
                }
            }

            .summary-container {
                display: flex;
                flex-direction: column;
                padding-left: 40px;
                gap: 2vh;

                @media only screen and (max-width: 1199px) {
                    padding: 2vh 0vw;
                }

                .up-summary-title {
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: white;

                    @media only screen and (max-width: 1200px) {
                        font-size: 1.2rem;
                    }

                    @media only screen and (max-width: 600px) {
                        font-size: 1.15rem;
                    }
                }

                .down-summary-edit {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    color: white;

                    .form-control {
                        height: 100%;
                        padding: 10px 15px;
                        border-radius: 10px;
                        background: #202020;
                        font-size: 1.05rem;
                        word-spacing: 2px;
                        color: white;

                        @media only screen and (max-width: 600px) {
                            font-size: 1rem;
                        }

                        @media only screen and (max-width: 1199px) {
                            height: 300px;
                        }
                    }

                    .edit-summary-btn {
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;

                        &:hover {
                            .icon-container {
                                background: #15E800;
                            }

                            .upload-text {
                                color: #15E800;
                            }
                        }

                        .icon-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 5px 5px;
                            border-radius: 5px;
                            font-size: 1.3rem;
                            background: #B3B3B3;
                            color: #1E1E1E;
                        }

                        .upload-text {
                            font-size: 1.1rem;
                            font-weight: 600;
                            text-decoration: underline;
                            color: #B3B3B3;
                        }
                    }
                }
            }
        }

        .middle-primary-info {
            width: 100%;

            .left-hire-me {
                display: flex;
                width: 100%;
                gap: 20px;

                .open-to-work {
                    width: 100px;
                    cursor: pointer;

                    img {
                        width: 100%;
                    }
                }

                .hire-me-text {
                    display: flex;
                    font-size: 1.05rem;
                    font-weight: 500;
                    color: white;

                    @media only screen and (max-width: 767px) {
                        font-size: 1rem;
                    }
                }
            }

            .right-primary {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-left: 40px;
                gap: 2vh;

                @media only screen and (max-width: 1199px) {
                    padding: 4vh 0vw;
                }

                .single-edit-box {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .up-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.15rem;
                        }
                    }

                    .down-input {
                        width: 100%;

                        .form-control {
                            padding: 7px 15px;
                            border-radius: 10px;
                            background: #202020;
                            font-size: 1.05rem;
                            word-spacing: 2px;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1rem;
                            }
                        }
                    }
                }

                .choose-department-box {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .up-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.15rem;
                        }
                    }

                    .down-select {
                        width: 100%;

                        .filter-dropdown {
                            width: 100%;
                            margin: 0vh 2vw 0vh 0vw;

                            .dropdown-toggle::after {
                                display: none !important;
                            }

                            .dropdown-menu.show {
                                width: 100%;
                                background: #202020;
                            }

                            .nav-link {
                                padding: 0;
                            }

                            .filter-by-options {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                padding: 7px 15px;
                                border: 1px solid white;
                                border-radius: 10px;
                                font-size: 1.1em;
                                background: #202020;
                                cursor: pointer;

                                .selected-blank-options {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: row;
                                    gap: 1vw;
                                    color: #595959;
                                }

                                .selected-options {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: row;
                                    gap: 1vw;
                                    color: white;
                                }

                                .filter-down-icon {
                                    font-size: 0.9rem;
                                    color: white;
                                }
                            }

                            .filter-menu-dropdown {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 0.5vh 1vw;
                                font-size: 1.1em;
                                color: white;
                            }
                        }
                    }
                }

                .add-skill-box {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .up-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.15rem;
                        }
                    }

                    .down-skills {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 2vh;

                        .form-control {
                            padding: 7px 15px;
                            border-radius: 10px;
                            background: #202020;
                            font-size: 1.05rem;
                            word-spacing: 2px;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1rem;
                            }
                        }

                        .skills-container {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            gap: 10px;

                            .single-skill {
                                display: flex;
                                align-items: center;
                                padding: 5px 20px;
                                gap: 10px;
                                border-radius: 20px;
                                background: #B3B3B3;
                                font-size: 1rem;
                                color: #202020;
                                cursor: pointer;

                                .close-icon {
                                    font-size: 1.3rem;
                                }

                                &:hover {
                                    background: #15E800;
                                    color: #202020;
                                }
                            }
                        }
                    }
                }

                .add-experience-box {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .up-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.15rem;
                        }
                    }

                    .down-experience-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 4vh;

                        .single-experience-box {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            gap: 1vh;

                            .up-title {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;

                                .left-job-title {
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                    color: white;

                                    @media only screen and (max-width: 1350px) {
                                        font-size: 1.1rem;
                                    }
                                }

                                .right-job-type {
                                    padding: 10px 20px;
                                    border-radius: 20px;
                                    font-size: 1rem;
                                    background: #202020;
                                    color: white;
                                }
                            }

                            .down-experience {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;

                                .left-company-title {
                                    font-size: 1.15rem;
                                    color: white;

                                    @media only screen and (max-width: 1350px) {
                                        font-size: 1.1rem;
                                    }
                                }

                                .right-experience-period {
                                    font-size: 1rem;
                                    color: white;
                                }
                            }

                            .bottom-divider {
                                margin-top: 1vh;
                                background: #B3B3B3;
                            }

                            .remove-container {
                                display: flex;
                                justify-content: right;

                                .remove-icon {
                                    font-size: 1.7rem;
                                    color: white;
                                    cursor: pointer;

                                    &:hover {
                                        color: #15E800;
                                    }
                                }
                            }
                        }
                    }

                    .new-experience-box {
                        width: 100%;

                        .single-left-box {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 2vh;
                            padding-right: 20px;
                            gap: 2vh;

                            @media only screen and (max-width: 991px) {
                                padding-right: 0px;
                            }

                            .up-label {
                                font-size: 1.15rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.1rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }

                            .down-input {
                                width: 100%;

                                .form-control {
                                    padding: 7px 15px;
                                    border-radius: 10px;
                                    background: #202020;
                                    font-size: 1.05rem;
                                    word-spacing: 2px;
                                    color: white;

                                    @media only screen and (max-width: 600px) {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }

                        .single-right-box {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 2vh;
                            padding-left: 20px;
                            gap: 2vh;

                            @media only screen and (max-width: 991px) {
                                padding-left: 0px;
                            }

                            .up-label {
                                font-size: 1.15rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.1rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }

                            .down-input {
                                width: 100%;

                                .form-control {
                                    padding: 7px 15px;
                                    border-radius: 10px;
                                    background: #202020;
                                    font-size: 1.05rem;
                                    word-spacing: 2px;
                                    color: white;

                                    @media only screen and (max-width: 600px) {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }

                        .select-job-type {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin: 2vh 0vw;
                            padding-right: 20px;

                            @media only screen and (max-width: 991px) {
                                padding-right: 0px;
                            }

                            .single-type-box {
                                width: 47%;
                                padding: 7px 0px;
                                border-radius: 20px;
                                text-align: center;
                                font-size: 1rem;
                                border: 1px solid #202020;
                                background: #202020;
                                color: white;
                                cursor: pointer;

                                &:hover {
                                    border-color: #15E800;
                                }
                            }

                            .single-type-box.active {
                                width: 47%;
                                padding: 7px 0px;
                                border-radius: 20px;
                                text-align: center;
                                font-size: 1rem;
                                border: 1px solid #15E800;
                                background: #15E800;
                                color: #202020;
                                cursor: pointer;
                            }
                        }

                        .submit-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            padding-left: 20px;

                            @media only screen and (max-width: 991px) {
                                padding-left: 0px;
                                margin-top: 2vh;
                            }

                            .submit-btn {
                                width: 100%;
                                padding: 10px 0px;
                                border: 1px solid #15E800;
                                border-radius: 20px;
                                text-align: center;
                                color: #15E800;
                                cursor: pointer;

                                &:hover {
                                    border-color: #15E800;
                                    background: #15E800;
                                    color: #202020;
                                }
                            }
                        }
                    }
                }

                .discard-save-container {
                    width: 100%;

                    .save-container {
                        width: 100%;
                        margin-bottom: 2vh;

                        .save-btn {
                            width: 100%;
                            padding: 7px 0px;
                            border: 1px solid #15E800;
                            border-radius: 20px;
                            text-align: center;
                            background: #131312;
                            color: #15E800;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                background: #15E800;
                                color: #202020;
                            }
                        }
                    }
                }
            }
        }
    }
}