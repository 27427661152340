.xemployed-homepage {
    background: #131312;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .xemployed-description {
        padding: 18vh 8vw 10vh 8vw;

        .left-title-container {
            display: flex;
            flex-direction: column;
            font-size: 2.3rem;
            font-weight: 800;

            @media only screen and (max-width: 1050px) {
                font-size: 2.2rem;
            }

            @media only screen and (max-width: 500px) {
                font-size: 2.0rem;
            }

            .title-effect {
                display: flex;
                width: 80%;

                @media only screen and (max-width: 1400px) {
                    width: 90%;
                }

                @media only screen and (max-width: 992px) {
                    width: 100%;
                }

                p {
                    margin: 0;
                    color: white;

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }
        }

        .right-description-container {
            display: flex;
            flex-direction: column;
            gap: 4vh;
            font-size: 1.15rem;

            @media only screen and (max-width: 991px) {
                margin-top: 4vh;
            }

            @media only screen and (max-width: 500px) {
                font-size: 1.1rem;
            }

            .up-description {
                width: 80%;
                font-size: 1.15rem;
                font-weight: normal;
                color: white;

                @media only screen and (max-width: 1200px) {
                    width: 90%;
                }

                @media only screen and (max-width: 992px) {
                    width: 100%;
                }

                @media only screen and (max-width: 500px) {
                    font-size: 1.1rem;
                }
            }

            .down-explore {
                display: flex;

                .explore-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 30px;
                    border: 1px solid #15E800;
                    border-radius: 20px;
                    font-size: 1.15rem;
                    color: #15E800;
                    cursor: pointer;

                    &:hover {
                        background: #15E800;
                        color: #202020;

                        .explore-icon {
                            color: #131312;
                        }
                    }

                    .explore-icon {
                        font-size: 1.2rem;
                        color: #15E800;
                    }
                }
            }
        }
    }

    .explore-talent {
        width: 100%;
        padding: 0vh 3vw 10vh 3vw;

        .inner-card {
            position: relative;
            display: flex;
            justify-content: center;
            padding: 0px 15px;

            .avatar-image {
                width: 100%;
                height: 400px;
                border-radius: 15px;
                object-fit: cover;
            }

            .avatar-effect {
                position: absolute;
                top: -20px;
                right: 15px;
            }

            .talent-info {
                position: absolute;
                left: 40px;
                bottom: 30px;
                font-size: 1.5rem;
                font-weight: 600;
                color: #15E800;
            }
        }
    }

    .xemployed-streamline {
        padding: 0vh 8vw 10vh 8vw;

        .left-streamline-container {
            display: flex;
            flex-direction: column;
            gap: 4vh;
            font-size: 2.3rem;
            font-weight: 800;

            @media only screen and (max-width: 1050px) {
                font-size: 2.2rem;
            }

            @media only screen and (max-width: 500px) {
                font-size: 2.0rem;
            }

            .title-effect {
                display: flex;

                p {
                    margin: 0;
                    color: white;

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }

            .streamline-description {
                font-size: 1.15rem;
                font-weight: normal;
                color: white;

                @media only screen and (max-width: 500px) {
                    font-size: 1.1rem;
                }
            }
        }

        .middle-blank {
            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }

        .right-video-container {
            width: 100%;

            @media only screen and (max-width: 992px) {
                margin-top: 4vh;
                padding: 0;
            }

            video {
                width: 100%;
            }
        }
    }

    .xemployed-help {
        padding: 0vh 8vw 10vh 8vw;

        .left-help-items {
            .help-item-container {
                padding: 0px 30px 30px 0px;

                @media only screen and (max-width: 1200px) {
                    padding: 0px 0px 30px 0px;
                }

                .single-help-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 30px 30px;
                    height: 30vh;
                    border-radius: 20px;
                    background: #202020;
                    color: white;

                    @media only screen and (max-width: 1500px) {
                        height: 35vh;
                    }

                    @media only screen and (max-width: 390px) {
                        height: 50vh;
                    }

                    .top-container {
                        display: flex;
                        flex-direction: column;
                        gap: 30px;

                        .up-title {
                            font-size: 1.25rem;
                            font-weight: 600;
                        }

                        .down-description {
                            font-size: 1.1rem;
                        }
                    }

                    .bottom-container {
                        display: flex;
                    }
                }
            }
        }

        .right-read-more {
            display: flex;
            flex-direction: column;
            gap: 2vh;

            .title-effect {

                @media only screen and (max-width: 1400px) {
                    width: 100%;
                }

                p {
                    margin: 0;
                    color: white;
                    text-align: right;
                    font-size: 2.3rem;
                    font-weight: 800;

                    @media only screen and (max-width: 1050px) {
                        font-size: 2.2rem;
                    }

                    @media only screen and (max-width: 500px) {
                        font-size: 2.0rem;
                    }

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }

            .down-read-more {
                display: flex;
                justify-content: right;

                .read-more-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 60px;
                    border: 1px solid #15E800;
                    border-radius: 20px;
                    font-size: 1.15rem;
                    color: #15E800;
                    cursor: pointer;

                    &:hover {
                        background: #15E800;
                        color: #202020;
                    }

                    .read-more-icon {
                        font-size: 1.2rem;
                        color: #15E800;
                    }
                }
            }
        }
    }

    .xemployed-partner {
        display: flex;
        width: 100%;
        padding: 0vh 8vw 10vh 8vw;

        .left-partner-container {
            display: flex;
            padding-right: 40px;
            justify-content: right;
            width: 100%;
            gap: 40px;
            height: 50px;

            @media only screen and (max-width: 991px) {
                justify-content: left;
                padding-right: 0px;
            }

            @media only screen and (max-width: 767px) {
                height: 40px;
            }

            @media only screen and (max-width: 650px) {
                height: 35px;
            }

            @media only screen and (max-width: 570px) {
                height: 25px;
            }

            @media only screen and (max-width: 570px) {
                height: 25px;
            }
        }

        .right-partner-container {
            display: flex;
            width: 100%;
            gap: 40px;
            height: 50px;

            @media only screen and (max-width: 991px) {
                margin-top: 4vh;
            }

            @media only screen and (max-width: 767px) {
                height: 40px;
            }

            @media only screen and (max-width: 650px) {
                height: 35px;
            }

            @media only screen and (max-width: 570px) {
                height: 25px;
            }

            @media only screen and (max-width: 450px) {
                height: 20px;
            }

        }
    }

    .xemployed-reviews {
        width: 100%;
        padding: 0vh 8vw 10vh 8vw;

        .left-review-text {
            display: flex;
            flex-direction: column;
            gap: 4vh;

            .title-effect {
                display: flex;
                font-size: 2.3rem;
                font-weight: 800;

                @media only screen and (max-width: 1050px) {
                    font-size: 2.2rem;
                }

                @media only screen and (max-width: 992px) {
                    font-size: 2.2rem;
                }

                p {
                    margin: 0;
                    color: white;

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }

            .review-description {
                font-size: 1.15rem;
                font-weight: normal;
                color: white;
            }
        }

        .right-review-box {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            gap: 4vh;

            @media only screen and (max-width: 1199px) {
                margin-top: 4vh;
                padding-left: 0;
            }

            .single-review-box {
                padding: 15px 20px;
                border-radius: 10px;
                background: #202020;
                z-index: 2;

                .left-text-container {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .review-address {
                        font-size: 1.1rem;
                        font-weight: 600;
                        color: #15E800;
                    }

                    .review-category {
                        font-size: 1.1rem;
                        font-weight: 500;
                        color: white;
                    }

                    .review-detail {
                        font-size: 1rem;
                        font-weight: normal;
                        color: white;
                    }
                }

                .right-rating-container {
                    display: flex;
                    justify-content: end;
                    align-items: flex-end;

                    @media only screen and (max-width: 768px) {
                        margin-top: 2vh;
                    }
                }
            }

            .review-effect {
                position: absolute;
                right: 0;
                z-index: 1;

                img {
                    width: 100%;
                }
            }
        }
    }

    .xemployed-owner {
        width: 100%;
        padding: 0vh 8vw 10vh 8vw;

        .owner-container {
            border-radius: 20px;
            background: #202020;
            font-size: 2.3rem;
            font-weight: 800;
            z-index: 2;

            @media only screen and (max-width: 1050px) {
                font-size: 2.2rem;
            }

            @media only screen and (max-width: 992px) {
                font-size: 2.2rem;
            }

            @media only screen and (max-width: 500px) {
                font-size: 2.0rem;
            }

            .left-description {
                display: flex;
                flex-direction: column;
                padding: 4vh 4vw 4vh 4vw;
                gap: 4vh;

                @media only screen and (max-width: 1500px) {
                    padding: 2vh 4vw 2vh 4vw;
                }

                @media only screen and (max-width: 1350px) {
                    padding: 2vh 4vw 2vh 4vw;
                    gap: 2vh;
                }

                @media only screen and (max-width: 992px) {
                    padding: 4vh 4vw 4vh 4vw;
                }

                .title-effect {
                    display: flex;

                    p {
                        margin: 0;
                        color: white;

                        span {
                            color: white;
                            -webkit-text-fill-color: transparent;
                            -webkit-text-stroke: 0.6px;
                        }
                    }
                }

                .owner-description {
                    font-size: 1.15rem;
                    font-weight: normal;
                    color: white;
                }
            }

            .right-contact-container {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                padding-bottom: 4vh;

                @media only screen and (max-width: 1500px) {
                    padding-bottom: 2vh;
                }

                @media only screen and (max-width: 992px) {
                    padding-bottom: 4vh;
                }

                .contact-us-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 60px;
                    border: 1px solid #15E800;
                    border-radius: 20px;
                    font-size: 1.15rem;
                    font-weight: normal;
                    color: #15E800;
                    cursor: pointer;

                    &:hover {
                        background: #15E800;
                        color: #202020;
                    }
                }
            }
        }
    }

    .xemployed-follow-us {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0vh 7vw 10vh 7vw;
        gap: 4vh;

        .follow-title-container {
            display: flex;
            justify-content: center;
            width: 50%;
            padding: 0vh 1vw;

            @media only screen and (max-width: 870px) {
                width: 70%;
            }

            @media only screen and (max-width: 630px) {
                width: 100%;
            }

            .title-effect {
                text-align: center;
                font-size: 2.3rem;
                font-weight: 800;

                @media only screen and (max-width: 1050px) {
                    font-size: 2.2rem;
                }

                @media only screen and (max-width: 992px) {
                    font-size: 2rem;
                }

                p {
                    margin: 0;
                    color: white;

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }
        }

        .twitter-container {
            width: 100%;

            .single-container {
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 0vh 1vw;

                @media only screen and (max-width: 992px) {
                    padding: 2vh 1vw;
                }
            }
        }
    }

    .xemployed-questions {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0vh 8vw 10vh 8vw;
        gap: 4vh;

        .questions-title-container {
            display: flex;
            justify-content: center;
            width: 50%;

            @media only screen and (max-width: 870px) {
                width: 70%;
            }

            @media only screen and (max-width: 630px) {
                width: 100%;
            }

            .title-effect {
                text-align: center;
                font-size: 2.3rem;
                font-weight: 800;

                @media only screen and (max-width: 1050px) {
                    font-size: 2.2rem;
                }

                @media only screen and (max-width: 992px) {
                    font-size: 2rem;
                }

                p {
                    margin: 0;
                    color: white;

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }
        }

        .questions-accordions {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 4vh;

            .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
                border-radius: 20px;
            }

            .css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {
                border-radius: 20px;
            }

            .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
                border-radius: 20px;
            }

            .question-summary {
                padding: 5px 30px;
                font-size: 1.15rem;
                font-weight: 600;

                @media only screen and (max-width: 768px) {
                    padding: 5px 15px;
                    font-size: 1.1rem;
                }
            }

            .question-detail-divider {
                margin: 0px 30px 10px 30px;
                background-color: #131312;

                @media only screen and (max-width: 768px) {
                    margin: 0px 15px 10px 15px;
                }
            }

            .question-detail-text {
                padding: 10px 30px;

                @media only screen and (max-width: 768px) {
                    padding: 10px 15px;
                }
            }
        }
    }
}