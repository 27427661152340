.post-articles {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0vh 40px 40px 40px;

    @media only screen and (max-width: 600px) {
        padding: 0vh 20px 40px 20px;
    }

    .top-already-posted {
        display: flex;
        flex-direction: column;
        gap: 4vh;

        .posted-label {
            font-size: 1.3rem;
            font-weight: 600;
            color: white;

            @media only screen and (max-width: 1200px) {
                font-size: 1.2rem;
            }

            @media only screen and (max-width: 600px) {
                font-size: 1.15rem;
            }
        }

        .posted-articles-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4vh;

            img {
                width: 150px;
            }

            p {
                font-size: 1.3rem;
                font-weight: 600;
                color: white;
            }
        }

        .posted-articles-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 2vh;

            .single-article-container {
                width: 100%;
                padding: 2vh 0vw;
                cursor: pointer;

                .left-cover-image {
                    width: 100%;
                    height: 300px;

                    img {
                        width: 100%;
                        height: 300px;
                        border-radius: 15px;
                        object-fit: cover;
                    }
                }

                .right-article-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .top-main-info {
                        display: flex;
                        flex-direction: column;
                        padding-left: 40px;
                        gap: 1vh;

                        @media only screen and (max-width: 1199px) {
                            margin-top: 2vh;
                            padding: 0px;
                        }

                        .top-title {
                            font-size: 1.3rem;
                            font-weight: 700;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.2rem;
                            }
                        }

                        .middle-author {
                            font-size: 1.1rem;
                            color: white;
                        }

                        .bottom-detail {
                            font-size: 1.1rem;
                            color: white;
                        }

                        .read-more {
                            font-size: 1.1rem;
                            font-weight: normal;
                            color: #15E800;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .right-job-control {
                        display: flex;
                        justify-content: right;
                        gap: 20px;

                        .edit-job-btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;

                            &:hover {
                                .icon-container {
                                    background: #15E800;
                                }

                                .edit-text {
                                    color: #15E800;
                                }
                            }

                            .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 5px 5px;
                                border-radius: 5px;
                                font-size: 1.3rem;
                                background: #B3B3B3;
                                color: #1E1E1E;
                            }

                            .edit-text {
                                font-size: 1.1rem;
                                font-weight: 600;
                                text-decoration: underline;
                                color: #B3B3B3;
                            }
                        }

                        .delete-job-btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;

                            &:hover {
                                .icon-container {
                                    background: #15E800;
                                }

                                .delete-text {
                                    color: #15E800;
                                }
                            }

                            .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 5px 5px;
                                border-radius: 5px;
                                font-size: 1.3rem;
                                background: #B3B3B3;
                                color: #1E1E1E;
                            }

                            .delete-text {
                                font-size: 1.1rem;
                                font-weight: 600;
                                text-decoration: underline;
                                color: #B3B3B3;
                            }
                        }
                    }
                }
            }
        }

        .new-article-container {
            display: flex;
            justify-content: center;

            .new-article-btn {
                width: 100%;
                padding: 10px 0px;
                border: 1px solid #15E800;
                border-radius: 20px;
                text-align: center;
                color: #15E800;
                cursor: pointer;

                &:hover {
                    border-color: #15E800;
                    background: #15E800;
                    color: #202020;
                }
            }
        }

        .article-detail-container {
            width: 100%;

            .post-new-article {
                .top-avatar-summary {
                    width: 100%;
                    padding-bottom: 4vh;

                    .row {
                        display: flex;
                    }

                    .avatar-upload {
                        position: relative;
                        width: 100%;
                        aspect-ratio: 1/1;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 50px;
                        border: 1px solid white;

                        @media only screen and (max-width: 1199px) {
                            margin-bottom: 2vh;
                        }

                        .upload-image-btn {
                            position: absolute;
                            right: 20px;
                            bottom: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;

                            &:hover {
                                .icon-container {
                                    background: #15E800;
                                }

                                .upload-text {
                                    color: #15E800;
                                }
                            }

                            .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 5px 5px;
                                border-radius: 5px;
                                font-size: 1.3rem;
                                background: #B3B3B3;
                                color: #1E1E1E;
                            }

                            .upload-text {
                                font-size: 1.1rem;
                                font-weight: 600;
                                text-decoration: underline;
                                color: #1E1E1E;
                            }
                        }
                    }

                    .right-detail-container {
                        display: flex;
                        flex-direction: column;
                        gap: 2vh;

                        .input-container {
                            display: flex;
                            flex-direction: column;
                            padding-left: 40px;
                            gap: 2vh;

                            @media only screen and (max-width: 1199px) {
                                padding: 0;
                            }

                            .up-title {
                                font-size: 1.3rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.2rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.15rem;
                                }
                            }

                            .down-edit {
                                position: relative;
                                width: 100%;
                                border-radius: 10px;
                                color: white;

                                .form-control {
                                    height: 100%;
                                    padding: 10px 15px;
                                    border-radius: 10px;
                                    background: #202020;
                                    font-size: 1.05rem;
                                    word-spacing: 2px;
                                    color: white;

                                    @media only screen and (max-width: 600px) {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .article-content {

                    .editor-toolbar {
                        border: none;
                        border-bottom: 1px solid #e5e5e5;
                        padding: 10px 15px;
                    }

                    .editor-wrapper {
                        border: 1px solid #e5e5e5;
                    }

                    .edit-project-editor {
                        min-height: 280px;
                        padding: 10px;
                        background: white;
                    }
                }

                .discard-create-container {
                    width: 100%;
                    margin-top: 4vh;

                    .create-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-right: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-right: 0px;
                        }

                        .create-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #15E800;
                            border-radius: 20px;
                            text-align: center;
                            background: #131312;
                            color: #15E800;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                background: #15E800;
                                color: #202020;
                            }
                        }
                    }

                    .discard-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-left: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-left: 0px;
                        }

                        .discard-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #B3B3B3;
                            border-radius: 20px;
                            text-align: center;
                            color: white;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                color: #15E800;
                            }
                        }
                    }
                }
            }

            .edit-existing-article {
                .top-avatar-summary {
                    width: 100%;
                    padding-bottom: 4vh;

                    .row {
                        display: flex;
                    }

                    .avatar-upload {
                        position: relative;
                        width: 100%;
                        aspect-ratio: 1/1;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 50px;
                        border: 1px solid white;

                        @media only screen and (max-width: 1199px) {
                            margin-bottom: 2vh;
                        }

                        .upload-image-btn {
                            position: absolute;
                            right: 20px;
                            bottom: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;

                            &:hover {
                                .icon-container {
                                    background: #15E800;
                                }

                                .upload-text {
                                    color: #15E800;
                                }
                            }

                            .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 5px 5px;
                                border-radius: 5px;
                                font-size: 1.3rem;
                                background: #B3B3B3;
                                color: #1E1E1E;
                            }

                            .upload-text {
                                font-size: 1.1rem;
                                font-weight: 600;
                                text-decoration: underline;
                                color: #1E1E1E;
                            }
                        }
                    }

                    .right-detail-container {
                        display: flex;
                        flex-direction: column;
                        gap: 2vh;

                        .input-container {
                            display: flex;
                            flex-direction: column;
                            padding-left: 40px;
                            gap: 2vh;

                            @media only screen and (max-width: 1199px) {
                                padding: 0;
                            }

                            .up-title {
                                font-size: 1.3rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 1200px) {
                                    font-size: 1.2rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.15rem;
                                }
                            }

                            .down-edit {
                                position: relative;
                                width: 100%;
                                border-radius: 10px;
                                color: white;

                                .form-control {
                                    height: 100%;
                                    padding: 10px 15px;
                                    border-radius: 10px;
                                    background: #202020;
                                    font-size: 1.05rem;
                                    word-spacing: 2px;
                                    color: white;

                                    @media only screen and (max-width: 600px) {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .article-content {

                    .editor-toolbar {
                        border: none;
                        border-bottom: 1px solid #e5e5e5;
                        padding: 10px 15px;
                    }

                    .editor-wrapper {
                        border: 1px solid #e5e5e5;
                    }

                    .edit-project-editor {
                        min-height: 280px;
                        padding: 10px;
                        background: white;
                    }
                }

                .discard-edit-container {
                    width: 100%;
                    margin-top: 4vh;

                    .edit-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-right: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-right: 0px;
                        }

                        .edit-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #15E800;
                            border-radius: 20px;
                            text-align: center;
                            background: #131312;
                            color: #15E800;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                background: #15E800;
                                color: #202020;
                            }
                        }
                    }

                    .discard-container {
                        width: 100%;
                        margin-bottom: 2vh;
                        padding-left: 20px;

                        @media only screen and (max-width: 991px) {
                            padding-left: 0px;
                        }

                        .discard-btn {
                            width: 100%;
                            padding: 10px 0px;
                            border: 1px solid #B3B3B3;
                            border-radius: 20px;
                            text-align: center;
                            color: white;
                            cursor: pointer;

                            &:hover {
                                border-color: #15E800;
                                color: #15E800;
                            }
                        }
                    }
                }
            }
        }
    }
}