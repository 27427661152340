.create-team {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0vh 40px 40px 40px;
    gap: 4vh;

    @media only screen and (max-width: 600px) {
        padding: 0vh 20px 40px 20px;
    }

    .top-already-existing {
        display: flex;
        flex-direction: column;
        gap: 4vh;

        .posted-label {
            font-size: 1.3rem;
            font-weight: 600;
            color: white;

            @media only screen and (max-width: 1200px) {
                font-size: 1.2rem;
            }

            @media only screen and (max-width: 600px) {
                font-size: 1.15rem;
            }
        }

        .posted-teams-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4vh;

            img {
                width: 200px;
            }

            p {
                font-size: 1.3rem;
                font-weight: 600;
                color: white;
            }
        }

        .posted-teams-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 2vh;

            .single-team {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 20px;
                border-radius: 15px;
                background: #B3B3B3;

                @media only screen and (max-width: 600px) {
                    padding: 15px;
                }

                .top-info {
                    display: flex;
                    flex-direction: column;
                    border-radius: 15px;
                    border: 1px solid #202020;
                    padding: 30px 20px;
                    gap: 2vh;

                    .primary-info {
                        width: 100%;

                        .left-icon-title {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            .title-container {
                                display: flex;
                                flex-direction: column;

                                .up-title {
                                    font-size: 1.4rem;
                                    color: #202020;

                                    @media only screen and (max-width: 1199px) {
                                        font-size: 1.3rem;
                                    }

                                    @media only screen and (max-width: 600px) {
                                        font-size: 1.2rem;
                                    }
                                }

                                .down-package {
                                    font-size: 1.3rem;
                                    color: #202020;

                                    @media only screen and (max-width: 1199px) {
                                        font-size: 1.2rem;
                                    }

                                    @media only screen and (max-width: 600px) {
                                        font-size: 1.1rem;
                                    }
                                }
                            }
                        }

                        .right-description {
                            font-size: 1.1rem;
                            color: #202020;

                            @media only screen and (max-width: 1199px) {
                                margin-top: 2vh;
                                font-size: 1.05rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1rem;
                            }
                        }
                    }

                    .edit-container {
                        display: flex;
                        justify-content: right;

                        .edit-btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;

                            &:hover {
                                .icon-container {
                                    background: #15E800;
                                }

                                .edit-text {
                                    color: #15E800;
                                }
                            }

                            .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 5px 5px;
                                border-radius: 5px;
                                font-size: 1.3rem;
                                background: #1E1E1E;
                                color: #B3B3B3;
                            }

                            .edit-text {
                                font-size: 1.1rem;
                                font-weight: 600;
                                text-decoration: underline;
                                color: #1E1E1E;
                            }
                        }
                    }
                }

                .team-members {
                    margin-top: 2vh;
                    width: 100%;

                    .empty-members {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: #202020;

                        .member-icon {
                            margin-right: 10px;
                            font-size: 2.3rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.1rem;
                        }
                    }

                    .members-container {
                        display: flex;
                        flex-direction: column;
                        gap: 2vh;

                        .single-team-member {
                            display: flex;
                            gap: 5px;
                            font-size: 1.1rem;
                            font-weight: 600;
                            color: #131312;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }

                            .img-container {
                                display: flex;
                                align-items: center;

                                img {
                                    margin: 0px 5px;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    border: 1px solid #131312;
                                }
                            }

                            .member-info {
                                display: flex;
                                align-items: center;
                                padding: 0 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .new-team-container {
        display: flex;
        justify-content: center;

        .new-team-btn {
            width: 100%;
            padding: 10px 0px;
            border: 1px solid #15E800;
            border-radius: 20px;
            text-align: center;
            color: #15E800;
            cursor: pointer;

            &:hover {
                border-color: #15E800;
                background: #15E800;
                color: #202020;
            }
        }
    }

    .team-detail-container {
        width: 100%;

        .create-new-team {
            width: 100%;

            .choose-xmoji {
                display: flex;
                flex-direction: column;

                .top-title {
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: white;

                    @media only screen and (max-width: 1200px) {
                        font-size: 1.2rem;
                    }

                    @media only screen and (max-width: 600px) {
                        font-size: 1.15rem;
                    }
                }

                .xmoji-container {
                    padding: 2vh 0vw;

                    .single-emoji {
                        display: flex;
                        justify-content: center;
                        padding: 2vh 0vw;
                        cursor: pointer;
                    }
                }
            }

            .single-input-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2vh;
                gap: 2vh;

                .up-title {
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: white;

                    @media only screen and (max-width: 1200px) {
                        font-size: 1.2rem;
                    }

                    @media only screen and (max-width: 600px) {
                        font-size: 1.15rem;
                    }
                }

                .down-input {
                    width: 100%;

                    .form-control {
                        padding: 7px 15px;
                        border-radius: 10px;
                        background: #202020;
                        font-size: 1.05rem;
                        word-spacing: 2px;
                        color: white;

                        @media only screen and (max-width: 600px) {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .discard-save-container {
                width: 100%;
                margin: 4vh 0vw;

                .save-container {
                    width: 100%;
                    margin-bottom: 2vh;
                    padding-right: 20px;

                    @media only screen and (max-width: 991px) {
                        padding-right: 0px;
                    }

                    .save-btn {
                        width: 100%;
                        padding: 10px 0px;
                        border: 1px solid #15E800;
                        border-radius: 20px;
                        text-align: center;
                        background: #131312;
                        color: #15E800;
                        cursor: pointer;

                        &:hover {
                            border-color: #15E800;
                            background: #15E800;
                            color: #202020;
                        }
                    }
                }

                .discard-container {
                    width: 100%;
                    margin-bottom: 2vh;
                    padding-left: 20px;

                    @media only screen and (max-width: 991px) {
                        padding-left: 0px;
                    }

                    .discard-btn {
                        width: 100%;
                        padding: 10px 0px;
                        border: 1px solid #B3B3B3;
                        border-radius: 20px;
                        text-align: center;
                        color: white;
                        cursor: pointer;

                        &:hover {
                            border-color: #15E800;
                            color: #15E800;
                        }
                    }
                }
            }
        }

        .edit-existing-team {
            width: 100%;

            .choose-xmoji {
                display: flex;
                flex-direction: column;

                .top-title {
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: white;

                    @media only screen and (max-width: 1200px) {
                        font-size: 1.2rem;
                    }

                    @media only screen and (max-width: 600px) {
                        font-size: 1.15rem;
                    }
                }

                .xmoji-container {
                    padding: 2vh 0vw;

                    .single-emoji {
                        display: flex;
                        justify-content: center;
                        padding: 2vh 0vw;
                        cursor: pointer;
                    }
                }
            }

            .single-input-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2vh;
                gap: 2vh;

                .up-title {
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: white;

                    @media only screen and (max-width: 1200px) {
                        font-size: 1.2rem;
                    }

                    @media only screen and (max-width: 600px) {
                        font-size: 1.15rem;
                    }
                }

                .down-input {
                    width: 100%;

                    .form-control {
                        padding: 7px 15px;
                        border-radius: 10px;
                        background: #202020;
                        font-size: 1.05rem;
                        word-spacing: 2px;
                        color: white;

                        @media only screen and (max-width: 600px) {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .discard-save-container {
                width: 100%;
                margin: 4vh 0vw;

                .save-container {
                    width: 100%;
                    margin-bottom: 2vh;
                    padding-right: 20px;

                    @media only screen and (max-width: 991px) {
                        padding-right: 0px;
                    }

                    .save-btn {
                        width: 100%;
                        padding: 10px 0px;
                        border: 1px solid #15E800;
                        border-radius: 20px;
                        text-align: center;
                        background: #131312;
                        color: #15E800;
                        cursor: pointer;

                        &:hover {
                            border-color: #15E800;
                            background: #15E800;
                            color: #202020;
                        }
                    }
                }

                .discard-container {
                    width: 100%;
                    margin-bottom: 2vh;
                    padding-left: 20px;

                    @media only screen and (max-width: 991px) {
                        padding-left: 0px;
                    }

                    .discard-btn {
                        width: 100%;
                        padding: 10px 0px;
                        border: 1px solid #B3B3B3;
                        border-radius: 20px;
                        text-align: center;
                        color: white;
                        cursor: pointer;

                        &:hover {
                            border-color: #15E800;
                            color: #15E800;
                        }
                    }
                }
            }
        }
    }
}