.job-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18vh 0vw 10vh 0vw;
    background: #131312;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .job-view-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4vh;

        .job-back {
            display: flex;
            justify-content: space-between;
            margin: 0vh 8vw;
            padding: 20px;
            border-radius: 20px;
            background: #202020;

            .left-go-back {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 10px;
                background: #B3B3B3;
                font-size: 1.5rem;
                color: #202020;
                cursor: pointer;

                &:hover {
                    background: #15E800;
                }
            }
        }

        .project-primary-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2vh;

            .project-logo {
                width: 300px;
                height: 300px;

                img {
                    width: 300px;
                    height: 300px;
                    border-radius: 20px;
                    object-fit: cover;
                }
            }

            .featured-icon {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 2.0rem;
                font-weight: 800;
                color: #15E800;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .project-primary {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2vh;

                .project-name {
                    text-align: right;
                    font-size: 2.3rem;
                    font-weight: 800;
                    letter-spacing: 6px;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke: 0.6px;
                    color: white;

                    @media only screen and (max-width: 1050px) {
                        font-size: 2.2rem;
                    }

                    @media only screen and (max-width: 500px) {
                        font-size: 2.0rem;
                    }
                }

                .project-job-title {
                    font-weight: 600;
                    font-size: 1.4rem;
                    color: #15E800;

                    @media only screen and (max-width: 1050px) {
                        font-size: 1.35rem;
                    }

                    @media only screen and (max-width: 500px) {
                        font-size: 1.3rem;
                    }
                }

                .project-min-budget {
                    font-weight: 600;
                    font-size: 1.3rem;
                    color: white;

                    @media only screen and (max-width: 1050px) {
                        font-size: 1.25rem;
                    }

                    @media only screen and (max-width: 500px) {
                        font-size: 1.15rem;
                    }
                }

                .project-employ-type {
                    display: flex;
                    gap: 10px;
                    font-weight: 600;
                    font-size: 1.3rem;
                    color: #15E800;

                    @media only screen and (max-width: 1050px) {
                        font-size: 1.25rem;
                    }

                    @media only screen and (max-width: 500px) {
                        font-size: 1.15rem;
                    }

                    .time-icon {
                        font-size: 1.8rem;
                    }
                }

                .project-socials {
                    display: flex;
                    gap: 30px;
                    color: white;

                    a {
                        color: white;

                        &:hover {
                            color: #15E800;
                        }

                        .social-icon {
                            font-size: 1.8rem;
                        }
                    }
                }

                .project-posted-date {
                    font-weight: 600;
                    font-size: 1.3rem;
                    color: white;

                    @media only screen and (max-width: 1050px) {
                        font-size: 1.25rem;
                    }

                    @media only screen and (max-width: 500px) {
                        font-size: 1.15rem;
                    }
                }
            }

            .project-poster {
                font-weight: 700;
                font-size: 1.3rem;
                color: #15E800;

                @media only screen and (max-width: 1050px) {
                    font-size: 1.25rem;
                }

                @media only screen and (max-width: 500px) {
                    font-size: 1.15rem;
                }
            }

            .project-description {
                margin: 2vh 0;
                padding: 0vh 20%;
                text-align: center;
                font-size: 1.15rem;
                color: white;

                @media only screen and (max-width: 1050px) {
                    padding: 0vh 10%;
                    font-size: 1.1rem;
                }

                @media only screen and (max-width: 500px) {
                    padding: 0vh 5%;
                    font-size: 1.05rem;
                }
            }

            .apply-btn {
                width: 300px;
                padding: 10px 0px;
                border: 1px solid #15E800;
                border-radius: 20px;
                text-align: center;
                background: #131312;
                color: #15E800;
                cursor: pointer;

                &:hover {
                    border-color: #15E800;
                    background: #15E800;
                    color: #202020;
                }
            }

            .apply-btn.active {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 300px;
                padding: 10px 0px;
                border: 1px solid #15E800;
                border-radius: 20px;
                font-size: 1.1rem;
                color: #15E800;
                cursor: pointer;

                &:hover {
                    background: #15E800;
                    color: #202020;
                }
            }

            .apply-btn.disable {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 300px;
                padding: 10px 0px;
                border: 1px solid #15E800;
                border-radius: 20px;
                font-size: 1.1rem;
                color: #15E800;
                cursor: not-allowed;

                &:hover {
                    background: #50a549;
                    color: #202020;
                }
            }
        }
    }
}