.profile-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18vh 8vw 10vh 8vw;
    background: #131312;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .direct-hire.active {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(16, 16, 16, 0.9);
        z-index: 3;

        .hire-modal {
            position: fixed;
            left: calc(50vw - 250px);
            top: 15vh;
            display: flex;
            flex-direction: column;
            width: 500px;
            padding: 20px;
            gap: 2vh;
            border-radius: 20px;
            background: #202020;

            @media only screen and (max-width: 500px) {
                left: 0;
                width: 100%;
                padding: 20px 10px;
            }

            .close-btn {
                display: flex;
                justify-content: right;

                .close-icon {
                    font-size: 1.4rem;
                    color: white;
                    cursor: pointer;
                }
            }

            .up-title {
                text-align: center;
                font-size: 2.1rem;
                font-weight: 800;

                @media only screen and (max-width: 1050px) {
                    font-size: 1.9rem;
                }

                @media only screen and (max-width: 992px) {
                    font-size: 1.8rem;
                }

                p {
                    margin: 0;
                    color: white;

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }

            .down-input-form {
                display: flex;
                flex-direction: column;
                padding: 2vh 30px;
                gap: 3vh;

                @media only screen and (max-width: 500px) {
                    padding: 2vh 15px;
                }

                .form-control {
                    padding: 7px 15px;
                    border-radius: 10px;
                    height: auto;
                }

                .submit-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 10px 0px;
                    border: 1px solid #15E800;
                    border-radius: 20px;
                    font-size: 1.15rem;
                    font-weight: 700;
                    color: #15E800;
                    cursor: pointer;

                    &:hover {
                        background: #15E800;
                        color: #202020;
                    }

                    .submit-icon {
                        margin-right: 10px;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    .direct-hire {
        position: fixed;
        top: -100vh;
        left: 0;
    }

    .direct-team.active {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(16, 16, 16, 0.9);
        z-index: 3;

        .hire-modal {
            position: fixed;
            left: calc(50vw - 250px);
            top: 15vh;
            display: flex;
            flex-direction: column;
            width: 500px;
            padding: 20px;
            gap: 2vh;
            border-radius: 20px;
            background: #202020;

            @media only screen and (max-width: 500px) {
                left: 0;
                width: 100%;
                padding: 20px 10px;
            }

            .close-btn {
                display: flex;
                justify-content: right;

                .close-icon {
                    font-size: 1.4rem;
                    color: white;
                    cursor: pointer;
                }
            }

            .up-title {
                text-align: center;
                font-size: 2.1rem;
                font-weight: 800;

                @media only screen and (max-width: 1050px) {
                    font-size: 1.9rem;
                }

                @media only screen and (max-width: 992px) {
                    font-size: 1.8rem;
                }

                p {
                    margin: 0;
                    color: white;

                    span {
                        color: white;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 0.6px;
                    }
                }
            }

            .down-teams-container {
                display: flex;
                flex-direction: column;
                gap: 2vh;
                padding: 0vh 2vw;

                .single-team {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 2vh 0;
                    padding: 1vh 30px;
                    gap: 20px;
                    border: 1px solid white;
                    border-radius: 20px;
                    font-size: 1.3rem;
                    font-weight: 700;
                    color: white;
                    cursor: pointer;

                    @media only screen and (max-width: 1050px) {
                        font-size: 1.25rem;
                    }

                    @media only screen and (max-width: 992px) {
                        font-size: 1.15rem;
                    }

                    img {
                        width: 50px;

                        @media only screen and (max-width: 992px) {
                            width: 40px;
                        }
                    }

                    &:hover {
                        background: #eeeded;
                        color: #202020;
                    }
                }
            }
        }
    }

    .direct-team {
        position: fixed;
        top: -100vh;
        left: 0;
    }

    .profile-view-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4vh;

        .profile-back {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 20px;
            border-radius: 20px;
            background: #202020;

            .left-go-back {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 10px;
                background: #B3B3B3;
                font-size: 1.5rem;
                color: #202020;
                cursor: pointer;

                &:hover {
                    background: #15E800;
                }
            }
        }

        .profile-container {
            width: 100%;

            .left-personal-profile {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 2vh;

                img {
                    width: 100%;
                    border-radius: 20px;
                }

                .hire-me-badge {
                    position: absolute;
                    top: 20px;
                    right: 30px;
                    width: 80px;
                }

                .full-name-container {
                    display: flex;
                    flex-direction: column;
                    gap: 1vh;

                    .full-name {
                        text-align: center;
                        font-size: 2.3rem;
                        font-weight: 800;

                        @media only screen and (max-width: 1050px) {
                            font-size: 2.2rem;
                        }

                        @media only screen and (max-width: 992px) {
                            font-size: 2rem;
                        }

                        p {
                            margin: 0;
                            color: white;

                            span {
                                color: white;
                                -webkit-text-fill-color: transparent;
                                -webkit-text-stroke: 0.6px;
                            }
                        }
                    }

                    .job-title {
                        text-align: center;
                        font-size: 1.3rem;
                        color: #15E800;
                    }
                }

                .department-title {
                    display: flex;
                    flex-direction: column;
                    gap: 1vh;

                    .up-label {
                        text-align: center;
                        font-size: 1.1rem;
                        color: white;
                    }

                    .down-department {
                        text-align: center;
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: white;
                    }
                }

                .social-icons-container {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .social-icons {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        width: 80%;
                        gap: 15px;

                        .single-social-icons {
                            font-size: 1.5rem;
                            color: white;

                            &:hover {
                                color: #15E800;
                            }
                        }
                    }
                }

                .review-count {
                    display: flex;
                    justify-content: center;
                    margin-top: 1vh;
                }

                .min-budget {
                    text-align: center;
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: white;
                }

                .invite-container {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .invite-team.active {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 80%;
                        padding: 10px 0px;
                        border: 1px solid #15E800;
                        border-radius: 20px;
                        font-size: 1.15rem;
                        color: #15E800;
                        cursor: pointer;

                        &:hover {
                            background: #15E800;
                            color: #202020;
                        }

                        .team-icon {
                            margin-right: 10px;
                            font-size: 1.8rem;
                        }
                    }

                    .invite-team.disable {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 80%;
                        padding: 10px 0px;
                        border: 1px solid #15E800;
                        border-radius: 20px;
                        font-size: 1.15rem;
                        color: #15E800;
                        cursor: not-allowed;

                        &:hover {
                            background: #50a549;
                            color: #202020;
                        }

                        .team-icon {
                            margin-right: 10px;
                            font-size: 1.8rem;
                        }
                    }
                }

                .propose-container {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .propose-collaboration.active {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 80%;
                        padding: 10px 0px;
                        border: 1px solid #15E800;
                        border-radius: 20px;
                        font-size: 1.1rem;
                        color: #15E800;
                        cursor: pointer;

                        &:hover {
                            background: #15E800;
                            color: #202020;
                        }

                        .propose-icon {
                            margin-right: 10px;
                            font-size: 1.8rem;
                        }
                    }

                    .propose-collaboration.disable {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 80%;
                        padding: 10px 0px;
                        border: 1px solid #15E800;
                        border-radius: 20px;
                        font-size: 1.1rem;
                        color: #15E800;
                        cursor: not-allowed;

                        &:hover {
                            background: #50a549;
                            color: #202020;
                        }

                        .propose-icon {
                            margin-right: 10px;
                            font-size: 1.8rem;
                        }
                    }
                }
            }

            .right-professional-profile {
                display: flex;
                flex-direction: column;
                gap: 4vh;

                @media only screen and (max-width: 991px) {
                    padding-top: 4vh;
                }

                .profile-summary {
                    display: flex;
                    flex-direction: column;
                    padding-left: 50px;
                    gap: 2vh;

                    @media only screen and (max-width: 991px) {
                        padding-left: 0px;
                        padding-top: 4vh;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 700;
                        color: #15E800;

                        @media only screen and (max-width: 1350px) {
                            font-size: 1.6rem;
                        }
                    }

                    span {
                        font-size: 1.2rem;
                        font-weight: normal;
                        line-height: 30px;
                        color: white;

                        @media only screen and (max-width: 1350px) {
                            font-size: 1.1rem;
                        }
                    }
                }

                .profile-skills {
                    display: flex;
                    flex-direction: column;
                    padding-left: 50px;
                    gap: 2vh;

                    @media only screen and (max-width: 991px) {
                        padding-left: 0px;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 700;
                        color: #15E800;

                        @media only screen and (max-width: 1350px) {
                            font-size: 1.6rem;
                        }
                    }

                    .skills-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        .single-skill {
                            padding: 10px 20px;
                            border-radius: 20px;
                            background: #B3B3B3;
                            font-size: 1.05rem;
                            font-weight: 500;
                            color: #202020;
                            cursor: cell;

                            &:hover {
                                background: #15E800;
                                color: #202020;
                            }
                        }
                    }
                }

                .profile-experience {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-left: 50px;
                    gap: 2vh;

                    @media only screen and (max-width: 991px) {
                        padding-left: 0px;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 700;
                        color: #15E800;

                        @media only screen and (max-width: 1350px) {
                            font-size: 1.6rem;
                        }
                    }

                    .experience-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 4vh;

                        .single-experience-box {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            gap: 1vh;

                            .up-title {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;

                                .left-job-title {
                                    font-size: 1.35rem;
                                    font-weight: 600;
                                    color: white;

                                    @media only screen and (max-width: 1350px) {
                                        font-size: 1.2rem;
                                    }
                                }

                                .right-job-type {
                                    padding: 10px 20px;
                                    border-radius: 20px;
                                    font-size: 1rem;
                                    background: #202020;
                                    color: white;
                                }
                            }

                            .down-experience {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;

                                .left-company-title {
                                    font-size: 1.15rem;
                                    color: white;

                                    @media only screen and (max-width: 1350px) {
                                        font-size: 1.1rem;
                                    }
                                }

                                .right-experience-period {
                                    font-size: 1rem;
                                    color: white;
                                }
                            }

                            .bottom-divider {
                                margin-top: 1vh;
                                background: #B3B3B3;
                            }
                        }
                    }
                }

                .profile-reviews {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-left: 50px;
                    gap: 2vh;

                    @media only screen and (max-width: 991px) {
                        padding-left: 0px;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 700;
                        color: #15E800;

                        @media only screen and (max-width: 1350px) {
                            font-size: 1.6rem;
                        }
                    }

                    .reviews-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 4vh;

                        .no-reviews {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-size: 1.1rem;
                            color: white;

                            .unhappy-icon {
                                font-size: 1.5rem;
                                color: white;
                            }
                        }

                        .single-review-box {
                            padding: 15px 20px;
                            border-radius: 10px;
                            background: #202020;
                            z-index: 2;

                            .left-text-container {
                                display: flex;
                                flex-direction: column;
                                gap: 5px;

                                .review-address {
                                    font-size: 1.1rem;
                                    font-weight: 600;
                                    color: #15E800;
                                }

                                .review-category {
                                    font-size: 1.1rem;
                                    font-weight: 500;
                                    color: white;
                                }

                                .review-detail {
                                    font-size: 1rem;
                                    font-weight: normal;
                                    color: white;
                                }
                            }

                            .right-rating-container {
                                display: flex;
                                justify-content: end;
                                align-items: flex-end;

                                @media only screen and (max-width: 768px) {
                                    margin-top: 2vh;
                                }
                            }
                        }
                    }

                    .add-review-container {
                        display: flex;
                        justify-content: left;
                        margin-top: 2vh;

                        .add-review-btn {
                            font-size: 1.2rem;
                            color: white;
                            cursor: pointer;

                            &:hover {
                                color: #15E800;
                            }

                            @media only screen and (max-width: 1350px) {
                                font-size: 1.1rem;
                            }
                        }

                        .add-review-box {
                            display: flex;
                            flex-direction: row;
                            padding: 15px 20px;
                            border-radius: 10px;
                            background: white;
                            width: 100%;
                            z-index: 2;

                            .left-text-container {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                width: 100%;

                                .form-control {
                                    padding: 7px 15px;
                                    border-radius: 10px;
                                    height: auto;
                                }

                                .review-address {
                                    font-size: 1.1rem;
                                    font-weight: 700;
                                    color: #202020;
                                }

                                .submit-btn {
                                    display: flex;
                                    justify-content: center;
                                    padding: 10px 20px;
                                    border-radius: 20px;
                                    background: #202020;
                                    font-size: 1.1rem;
                                    color: white;
                                    cursor: pointer;

                                    &:hover {
                                        background: #15E800;
                                    }
                                }
                            }

                            .right-rating-container {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                width: 140px;
                                padding-left: 10px;

                                .close-add-review {
                                    display: flex;
                                    justify-content: right;

                                    .close-icon {
                                        margin-right: 10px;
                                        font-size: 1.5rem;
                                        cursor: pointer;

                                        @media only screen and (max-width: 600px) {
                                            font-size: 1.3rem;
                                        }
                                    }
                                }

                                @media only screen and (max-width: 768px) {
                                    margin-top: 2vh;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}