.app-footer {
    width: 100%;
    background: #202020;
    color: white;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .top-footer {
        width: 100%;
        display: flex;
        padding: 3vh 2vw 3vh 2vw;

        .logo-part {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .about-project {
            display: flex;
            flex-direction: column;
            align-items: center;

            .arranged-description {
                display: flex;
                flex-direction: column;
                gap: 3vh;

                .xemployed-logo {
                    p {
                        margin: 0;
                        font-size: 2rem;
                        font-weight: 700;
                        color: #ffffff;

                        span {
                            font-size: 2rem;
                            font-weight: 700;
                            color: #15E800;
                        }
                    }
                }

                .blxc-logo {
                    font-size: 1.1rem;
                    font-weight: 700;

                    img {
                        margin-left: 15px;
                        width: 50px;
                    }
                }
            }
        }

        .essential-link {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media only screen and (max-width: 768px) {
                align-items: flex-start;
            }

            .arranged-menus {
                display: flex;
                flex-direction: column;

                a {
                    width: max-content;
                    margin: 1vh 0vw;
                    font-size: 15px;
                    line-height: 20px;
                    color: white;
                    cursor: pointer;

                    &:hover {
                        color: #15E800;
                        text-decoration: underline;
                    }
                }
            }
        }

        .social-link {
            display: flex;
            justify-content: center;
            align-items: center;

            .arranged-menus {
                display: flex;
                flex-direction: row;
                gap: 30px;

                a {
                    font-size: 20px;
                    line-height: 20px;
                    color: #15E800;
        
                    &:hover {
                        color: white;
                    }
                }    
            }
        }

        h4 {
            width: max-content;
            margin: 1vh 0vw 3vh 0vw;
            font-weight: 500 !important;
        }

        p {
            width: max-content;
            margin: 1vh 0vw;
            font-size: 15px;
            line-height: 20px;

            &:hover {
                color: #F723AF;
            }
        }

        span {
            margin: 1vh 0vw;
            font-size: 15px;
            line-height: 30px;
            letter-spacing: 0.5px;
        }
    }
}