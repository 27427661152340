.right-menu-bar.active {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    z-index: 1001;

    .left-blank-menu {
        width: calc(100vw - 400px);
        height: 100vh;
        background: #101010;
        opacity: 0.9;

        @media only screen and (max-width: 1400px) {
            width: calc(100vw - 370px);
        }

        @media only screen and (max-width: 500px) {
            width: calc(100vw - 350px);
        }
    }

    .right-main-menu {
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 100vh;
        padding: 18vh 50px;
        gap: 2vh;
        background: #202020;

        @media only screen and (max-width: 1400px) {
            width: 370px;
        }

        @media only screen and (max-width: 500px) {
            width: 350px;
            padding: 18vh 30px;
        }

        .top-user-address {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 2vh;
            gap: 20px;
            font-size: 1.15rem;
            font-weight: 600;
            color: #15E800;

            @media only screen and (max-width: 500px) {
                font-size: 1.1rem;
            }

            .wallet-icon {
                font-size: 1.8rem;
            }
        }

        .single-normal-menu {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 30px;
            gap: 20px;
            border: 1px solid #131312;
            border-radius: 20px;
            font-size: 1.1rem;
            font-weight: 600;
            background: #131312;
            color: white;
            cursor: pointer;

            .menu-icon {
                font-size: 1.5rem;
                color: #15E800;
            }

            &:hover {
                border: 1px solid #15E800;
            }
        }

        .single-logout-menu {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 30px;
            gap: 20px;
            border: 1px solid white;
            border-radius: 20px;
            font-size: 1.1rem;
            font-weight: 600;
            color: white;
            cursor: pointer;

            .menu-icon {
                font-size: 1.5rem;
                color: white;
            }

            &:hover {
                border: 1px solid #15E800;
                color: #15E800;

                .menu-icon {
                    font-size: 1.5rem;
                    color: #15E800;
                }    
            }
        }
    }
}

.right-menu-bar {
    position: fixed;
    top: 0;
    right: -200vw;
}