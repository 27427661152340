.xemployed-teams {
    width: 100%;
    background: #131312;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .top-teams-title {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 18vh 8vw 10vh 8vw;

        .title-effect {
            text-align: center;
            font-size: 2.3rem;
            font-weight: 800;

            @media only screen and (max-width: 1050px) {
                font-size: 2.2rem;
            }

            @media only screen and (max-width: 992px) {
                font-size: 2rem;
            }

            p {
                margin: 0;
                color: white;

                span {
                    color: white;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke: 0.6px;
                }
            }
        }
    }

    .bottom-all-teams {
        width: 100%;
        padding: 0vh 7vw 10vh 7vw;

        .single-team-container {
            width: 100%;
            padding: 10px 20px;
            cursor: pointer;

            @media only screen and (max-width: 600px) {
                padding: 10px 0px;
            }

            .inner-div-container {
                display: flex;
                flex-direction: column;
                padding: 3vh 20px;
                border-radius: 20px;
                background: #202020;
                gap: 2vh;

                .top-icon-title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 20px;

                    .right-container {
                        display: flex;
                        flex-direction: column;

                        .up-title {
                            font-size: 1.3rem;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.2rem;
                            }
                        }

                        .down-package {
                            font-size: 1.2rem;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.1rem;
                            }
                        }
                    }
                }

                .middle-description {
                    height: 10vh;
                    overflow-y: auto;
                    font-size: 1.1rem;
                    color: white;

                    @media only screen and (max-width: 600px) {
                        font-size: 1.05rem;
                    }
                }

                .bottom-team-members {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;

                    .all-members {
                        display: flex;
                        flex-direction: column;
                        gap: 2vh;

                        .single-team-member {
                            display: flex;
                            gap: 5px;
                            font-size: 1.1rem;
                            font-weight: 600;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }

                            .img-container {
                                display: flex;
                                align-items: center;

                                img {
                                    margin: 0px 5px;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    border: 1px solid white;
                                }
                            }

                            .member-info {
                                display: flex;
                                align-items: center;
                                padding: 0 10px; 
                            }
                        }    
                    }

                    .empty-members {
                        display: flex;
                        justify-content: center;
                        font-size: 1.1rem;
                        font-weight: 600;
                        color: white;

                        .member-icon {
                            margin-right: 10px;
                            font-size: 2.0rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.05rem;
                        }
                    }
                }
            }
        }
    }
}