.main-connection-card {
    width: 100%;
    padding: 20vh 0vw 10vh 0vw;
    font-family: Arial, Helvetica, sans-serif;
    background: #202020;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .connection-label {
        text-align: center;
        font-weight: 1000;
        font-size: 1.6em;
        color: white;

        .back-home-btn {
            font-size: 1.5em;
            margin-left: 2vw;
            cursor: pointer;
        }
    }

    .wallet-connection-description {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 0vh 2vw;
        margin-top: 3vh;
        font-size: 1.05rem;
        color: white;
    }

    .wallet-connect-container {
        margin-top: 4vh;
        padding: 0vh 15%;

        @media only screen and (max-width: 1300px) {
            padding: 0vh 5%;
        }

        @media only screen and (max-width: 500px) {
            padding: 0vh 5%;
        }

        .connection-btn {
            position: relative;
            display: flex;
            margin: 2vh 0vw;
            width: 98%;
            height: 7vh;
            padding: 30px;
            border: 1px solid #131312;
            border-radius: 30px;
            background: #131312;
            color: white;
            cursor: pointer;

            &:hover {
                border: 1px solid #15E800;
            }

            .flex-div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0vh 1vw;

                img {
                    width: 35px;
                }

                span {
                    width: 100%;
                    font-size: 1em;
                    padding-left: 12px;
                    font-weight: 400;
                }
            }
        }

        .connection-btn>button {
            position: absolute;
            left: 0vw;
            top: 0vh;
            width: 100%;
            height: 100%;
            opacity: 0;
            display: block;

            a,
            button {
                width: 100%;
                height: 100%;
            }
        }
    }
}