.all-proposals {
    width: 100%;
    padding: 0vh 40px 40px 40px;

    @media only screen and (max-width: 600px) {
        padding: 0vh 20px 40px 20px;
    }

    .proposals-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4vh;
        padding: 6vh 0vw 2vh 0vw;

        img {
            width: 200px;
        }

        p {
            font-size: 1.3rem;
            font-weight: 600;
            color: white;
        }
    }

    .proposals-container {
        display: flex;
        flex-direction: column;
        gap: 4vh;

        .team-requests {
            display: flex;
            flex-direction: column;
            gap: 2vh;

            .up-request-type {
                font-size: 1.3rem;
                font-weight: 600;
                color: white;

                @media only screen and (max-width: 1200px) {
                    font-size: 1.2rem;
                }

                @media only screen and (max-width: 600px) {
                    font-size: 1.15rem;
                }
            }

            .requests-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 2vh;

                .single-request {
                    width: 100%;
                    padding: 20px 30px;
                    border-radius: 20px;
                    background: #202020;

                    .left-team-members {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .team-members {
                            display: flex;
                            flex-direction: column;
                            gap: 2vh;

                            .top-title {
                                font-size: 1.2rem;
                                font-weight: 700;
                                color: white;
                            }

                            .bottom-photos {
                                display: flex;
                                gap: 10px;

                                img {
                                    width: 30px;
                                    height: 30px;
                                    object-fit: fill;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }

                    .right-team-detail {
                        display: flex;
                        flex-direction: column;
                        gap: 2vh;

                        @media only screen and (max-width: 991px) {
                            margin-top: 2vh;
                        }

                        .top-team-description {
                            font-size: 1.1rem;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }

                        .bottom-btns {
                            display: flex;
                            gap: 10px;

                            .left-join-btn {
                                padding: 5px 25px;
                                border: 1px solid #15E800;
                                border-radius: 20px;
                                color: #15E800;
                                cursor: pointer;

                                &:hover {
                                    background: #15E800;
                                    color: #202020;
                                }
                            }

                            .right-reject-btn {
                                padding: 5px 25px;
                                border: 1px solid #B3B3B3;
                                border-radius: 20px;
                                background: #B3B3B3;
                                color: #202020;
                                cursor: pointer;

                                &:hover {
                                    border: 1px solid #15E800;
                                    color: #202020;
                                }
                            }
                        }
                    }
                }
            }
        }

        .collaboration-requests {
            display: flex;
            flex-direction: column;
            gap: 4vh;

            .up-request-type {
                font-size: 1.3rem;
                font-weight: 600;
                color: white;

                @media only screen and (max-width: 1200px) {
                    font-size: 1.2rem;
                }

                @media only screen and (max-width: 600px) {
                    font-size: 1.15rem;
                }
            }

            .requests-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 2vh;

                .single-request {
                    display: flex;
                    flex-direction: column;
                    padding: 20px 30px;
                    gap: 10px;
                    border-radius: 20px;
                    background: #202020;

                    .department-type {
                        display: flex;
                        gap: 15px;

                        .left-title {
                            font-size: 1.2rem;
                            font-weight: normal;
                            color: white;

                            @media only screen and (max-width: 1200px) {
                                font-size: 1.1rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }

                        .right-detail {
                            font-size: 1.2rem;
                            font-weight: 600;
                            color: #15E800;

                            @media only screen and (max-width: 1200px) {
                                font-size: 1.1rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }
                    }

                    .contact-info {
                        display: flex;
                        gap: 15px;

                        .left-title {
                            font-size: 1.2rem;
                            font-weight: normal;
                            color: white;

                            @media only screen and (max-width: 1200px) {
                                font-size: 1.1rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }

                        .right-detail {
                            font-size: 1.2rem;
                            font-weight: 600;
                            color: #15E800;

                            @media only screen and (max-width: 1200px) {
                                font-size: 1.1rem;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }
                    }

                    .proposal-detail {
                        font-size: 1.1rem;
                        color: white;

                        @media only screen and (max-width: 1200px) {
                            font-size: 1.05rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-size: 1.05rem;
                        }
                    }

                    .remove-container {
                        display: flex;
                        justify-content: right;
                        margin-top: 1vh;

                        .remove-btn {
                            padding: 7px 20px;
                            border-radius: 30px;
                            font-size: 1.05rem;
                            font-weight: 600;
                            background: #B3B3B3;
                            color: #202020;
                            cursor: pointer;

                            &:hover {
                                background: #15E800;
                            }
                        }
                    }
                }
            }
        }

        .job-proposals {
            display: flex;
            flex-direction: column;
            gap: 4vh;

            .up-request-type {
                font-size: 1.3rem;
                font-weight: 600;
                color: white;

                @media only screen and (max-width: 1200px) {
                    font-size: 1.2rem;
                }

                @media only screen and (max-width: 600px) {
                    font-size: 1.15rem;
                }
            }

            .requests-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 2vh;

                .single-request {
                    padding: 20px 30px;
                    border-radius: 20px;
                    background: #202020;

                    .left-candidate-info {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        img {
                            width: 70px;
                            height: 70px;
                            border: 1px solid #15E800;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        .right-info {
                            display: flex;
                            flex-direction: column;
                            gap: 1vh;

                            .top-name {
                                font-size: 1.15rem;
                                font-weight: 600;
                                color: white;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }

                            .bottom-applied {
                                font-size: 1.15rem;
                                font-weight: 600;
                                color: #15E800;

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }
                            }
                        }
                    }

                    .right-job-info {
                        display: flex;
                        flex-direction: column;
                        gap: 1vh;

                        @media only screen and (max-width: 991px) {
                            margin-top: 2vh;
                        }

                        .job-title {
                            font-size: 1.15rem;
                            font-weight: 600;
                            color: white;

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }


                        .job-type {
                            display: flex;
                            gap: 10px;
                            font-size: 1.15rem;
                            font-weight: 600;
                            color: white;

                            .time-icon {
                                font-size: 1.6rem;
                                color: white;
                            }

                            @media only screen and (max-width: 600px) {
                                font-size: 1.05rem;
                            }
                        }

                        .delete-container {
                            display: flex;

                            .delete-btn {
                                padding: 5px 30px;
                                border: 1px solid #B3B3B3;
                                border-radius: 30px;
                                font-size: 1.15rem;
                                font-weight: 600;
                                background: #B3B3B3;
                                color: rgb(44, 43, 43);
                                cursor: pointer;

                                &:hover {
                                    border: 1px solid #15E800;
                                    color: #15E800;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.05rem;
                                }    
                            }
                        }
                    }
                }
            }
        }
    }
}