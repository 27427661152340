.xemployed-avenue {
    width: 100%;
    background: #131312;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-left: 0;
        padding-right: 0;
    }

    .top-avenue-title {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 18vh 8vw 10vh 8vw;

        .title-effect {
            text-align: center;
            font-size: 2.3rem;
            font-weight: 800;

            @media only screen and (max-width: 1050px) {
                font-size: 2.2rem;
            }

            @media only screen and (max-width: 992px) {
                font-size: 2rem;
            }

            p {
                margin: 0;
                color: white;

                span {
                    color: white;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke: 0.6px;
                }
            }
        }
    }

    .bottom-all-articles {
        width: 100%;
        padding: 0vh 7vw 10vh 7vw;

        .single-article-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 2vh 1vw;
            gap: 2vh;
            cursor: pointer;

            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                border-radius: 10px;
            }

            .top-article-title {
                font-size: 1.3rem;
                font-weight: 600;
                color: white;

                @media only screen and (max-width: 1199px) {
                    font-size: 1.25rem;
                }
    
                @media only screen and (max-width: 600px) {
                    font-size: 1.2rem;
                }    
            }
            
            .middle-article-author {
                font-size: 1.1rem;
                font-weight: normal;
                color: white;
    
                @media only screen and (max-width: 600px) {
                    font-size: 1.05rem;
                }    

                .twitter-icon {
                    font-size: 1.5rem;
                    color: white;
    
                    &:hover {
                        color: #15E800;
                    }
                }
            }

            .bottom-article-content {
                font-size: 1.1rem;
                font-weight: normal;
                color: white;

                @media only screen and (max-width: 600px) {
                    font-size: 1.05rem;
                }    
            }

            .read-more-btn {
                font-size: 1.1rem;
                font-weight: normal;
                color: #15E800;

                @media only screen and (max-width: 600px) {
                    font-size: 1.05rem;
                }
                
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}